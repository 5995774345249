<div class="form-row " >
  <div class="form-group">
    <label class="mb-3">Selecciona si es asegurado o beneficiario</label>
    <div class="d-start px-md-4 gap-16 ">
      <div class="form-check form-check-inline ">
        <input class="form-check-input" type="radio" name="tipodeUsuarioAlta" id="tipodeUsuarioAlta1" [value]="1"  onchange="radioButton(this); porTitular()"
               [(ngModel)]="dataRequest.typeAdd" (click)="invokeFunctionChangeOperation(1)">
        <label class="form-check-label  mx-0" for="tipodeUsuarioAlta1">
          <p class="">Asegurado titular</p>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="tipodeUsuarioAlta" id="tipodeUsuarioAlta2" [value]="2" onchange="radioButton(this); porBeneficiario() "
               [(ngModel)]="dataRequest.typeAdd" (click)="invokeFunctionChangeOperation(2)">
        <label class="form-check-label mx-0" for="tipodeUsuarioAlta2">
          <p class="">Beneficiario del titular</p>
        </label>
      </div>

    </div>
  </div>
</div>
<!-- Por titular -->
<div id="porTitular" class="seleccionasteAlta d-none" *ngIf="responseService == 0  && dataRequest.typeAdd == 1">
  <form action="">
    <!--- Buscar cliente-->
    <div class="form-row mt-3">
      <div class="form-group">
        <label >Razón Social</label>
        <select class="form-select" id="razonSocial" name="razonSocial" [(ngModel)]="dataRequest.clientId"
                name="client" required >
          <option *ngFor="let item of clientList" [value]="item.clientId"
          >{{item.name}}</option>
        </select>
      </div>
    </div>

    <div class="separador-tarjetas">
      <h4>Selecciona la póliza maestra</h4>
      <hr>
    </div>
    <!-- Seleccionar poliza maestra -->
    <div class="form-row">
      <div class="form-group" *ngIf="policyList.length > 0">
        <div class="d-between">
          <div class="form-check form-check-inline mb-3" *ngFor="let p of policyList">
            <input class="form-check-input" type="radio" name="polizaMaestraSolicitud" id="polizaMaestraSolicitud {{p.idPolicy}}"
                   value="option{{p.policyId}}"
                   onchange="pruebaCheckbox(this)" >
            <label class="form-check-label poliza-maestra mx-0" for="polizaMaestraSolicitud {{p.idPolicy}}">

              <h4 class="">{{p.subranch | titlecase}} <br> {{p.numberPolicy}}</h4>
              <h5>{{p.insurance}}</h5>

              <div class="d-column-start mt-3">
                <div class="form-check form-check-inline" *ngFor="let c of p.policyCategoriesList">
                  <input class="form-check-input" type="checkbox" name="directoresSolicitud1" id="directoresSolicitud{{c.idCategory}}" onchange="radioButton(this)"
                         [(ngModel)]="c.selected" [ngModelOptions]="{standalone: false}" >
                  <label class="form-check-label  mx-0" for="directoresSolicitud{{c.idCategory}}">
                    <p class="">{{c.category | titlecase}}</p>
                  </label>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- SEPARADOR -->
    <div class="separador-tarjetas">
      <h4>Datos del asegurado</h4>
      <hr>
    </div>
    <!-- Nombre -->
    <div class="form-row">
      <div class="form-group">
        <label for="nombre">Nombre</label>
        <input type="text" class="form-control ammiaAdmin-style" id="nombre" name="nombre"
               [(ngModel)]="dataToSend.name" name="name" required upperCaseText >
        <div class="invalid-feedback">
          Please choose a username.
        </div>
      </div>
    </div>
    <!-- Apellidos -->
    <div class="form-row">
      <div class="form-group">
        <label for="apellidoPaterno">Apellido paterno</label>
        <input type="text" class="form-control ammiaAdmin-style" id="apellidoPaternoT" name="apellidoPaterno"
               [(ngModel)]="dataToSend.pLastname" name="pLastname" required upperCaseText>
      </div>
      <div class="form-group">
        <label for="apellidoMaterno">Apellido materno</label>
        <input type="text" class="form-control ammiaAdmin-style" id="apellidoMaternoT" name="apellidoMaterno"
               [(ngModel)]="dataToSend.sLastname" name="sLastname" required upperCaseText>
      </div>

    </div>
    <div class="form-row">
      <div class="form-group">
        <label class="control-label">Género</label>
        <div class="form-group">
          <label class="radio-inline" *ngFor="let item of gendersCatalog">
            <input type="radio" name="gender" [value]="item.genderId" [(ngModel)]="dataToSend.gender" required>
            {{item.description}}
          </label>
        </div>
      </div>
      <div class="form-group">
        <label for="numeroEmpleado">Número de empleado</label>
        <input type="text" class="form-control ammiaAdmin-style" id="numeroEmpleado" name="numeroEmpleado"
               [(ngModel)]="dataToSend.employeeNumber" name="employeeNumber" required>
      </div>

    </div>
    <div class="form-row">
      <div class="form-group">
        <label for="correoElectronico">Correo electrónico</label>
        <input type="text" class="form-control ammiaAdmin-style" id="correoElectronico" name="correoElectronico"
               [(ngModel)]="dataToSend.email" name="email" required>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="fechaNacimiento">Fecha de nacimiento</label>
        <div class="input-group">
          <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
          <input type="text"
                 placeholder="00-00-0000"
                 class="form-control"
                 bsDatepicker
                 [bsConfig]="bsBirthdate"
                 [(ngModel)]="dataToSend.birthdate"
                 name="fechaNacimiento"
                 required>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label for="fechaAlta">Fecha de alta</label>
        <div class="input-group">
          <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
          <input type="text"
                 placeholder="00-00-0000"
                 class="form-control"
                 bsDatepicker
                 [bsConfig]="bsEntryDate"
                 [(ngModel)]="dataToSend.entryDate"
                 name="entryDate"
                 required>
        </div>
      </div>

    </div>
    <div class="form-row">
      <div class="form-group">
        <label for="salary">Sueldo mensual</label>
        <div class="input-group">
          <div class="input-group-addon">$</div>
          <input type="number" step="0.01" class="form-control" [(ngModel)]="dataToSend.salary" name="salary" id="salary">
        </div>
      </div>
    </div>
    <!-- RECONOCIMIENTO DE ANTIGUEDAD -->
    <div class="reconocimiento-antiguedad" id="reconocimientoAntiguedad">
      <div class="d-center gap-8">
        <img src="../../assets/img/ammia/iconos/icono_cargadocumento-gris.svg" alt="">
        <div>
          <h5>Reconocimiento de antigüedad</h5>
          <div class="form-group">
            <label class="text-primary" *ngIf="fileUpload == undefined" [hidden]="error.active">----------</label>
            <label class="text-primary" style="width: 100%" *ngIf="fileUpload != undefined" [hidden]="error.active">
              {{ fileUpload.name }} <span class="pull-right">{{shared.formatBytes(fileUpload.size,2)}}</span>
            </label>
            <label class="text-danger" [hidden]="!error.active">{{ error.msg }}</label>
          </div>
        </div>

      </div>
      <div class="">
        <div class="form-group">
          <label class="btn btn-custom btn-sm btn-block">Subir documento
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z"/>
            </svg>
            <input type="file" style="display: none" (change)="onChangeInputFile($event)" name="antiquie">
          </label>
        </div>
      </div>
    </div>
    <!--<div class="reconocimiento-antiguedad d-none" id="reconocimientoAntiguedadCargado">
      <div class="d-center gap-8">
        <img src="img/iconos/icono_cargadocumento-gris.svg" alt="">
        <div>
          <h5>Reconocimiento de antigüedad</h5>
          <p>documento_cargado.pdf</p>
        </div>

      </div>
      <div class="">
        <button type="button"  href="" class="enlace"  >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.656353 1.77101C1.07661 1.35076 1.6466 1.11466 2.24093 1.11466H7.44267C7.85949 1.11466 8.19739 1.45256 8.19739 1.86938C8.19739 2.28619 7.85949 2.62409 7.44267 2.62409H2.24093C2.04692 2.62409 1.86087 2.70116 1.72368 2.83834C1.5865 2.97552 1.50943 3.16158 1.50943 3.35559V13.7591C1.50943 13.9531 1.5865 14.1391 1.72368 14.2763C1.86087 14.4135 2.04692 14.4906 2.24093 14.4906H12.6444C12.8384 14.4906 13.0245 14.4135 13.1617 14.2763C13.2988 14.1391 13.3759 13.9531 13.3759 13.7591V8.55733C13.3759 8.14051 13.7138 7.80261 14.1306 7.80261C14.5474 7.80261 14.8853 8.14051 14.8853 8.55733V13.7591C14.8853 14.3534 14.6492 14.9234 14.229 15.3436C13.8087 15.7639 13.2387 16 12.6444 16H2.24093C1.6466 16 1.07661 15.7639 0.656353 15.3436C0.236097 14.9234 0 14.3534 0 13.7591V3.35559C0 2.76126 0.236097 2.19127 0.656353 1.77101Z" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7383 1.50943C13.5388 1.50943 13.3474 1.58869 13.2063 1.72977L6.60525 8.33086L6.25061 9.74939L7.66914 9.39475L14.2702 2.79367C14.4113 2.65259 14.4906 2.46124 14.4906 2.26172C14.4906 2.06221 14.4113 1.87086 14.2702 1.72977C14.1291 1.58869 13.9378 1.50943 13.7383 1.50943ZM12.139 0.662444C12.5631 0.238288 13.1384 0 13.7383 0C14.3381 0 14.9134 0.238288 15.3376 0.662444C15.7617 1.0866 16 1.66188 16 2.26172C16 2.86157 15.7617 3.43685 15.3376 3.86101L8.58866 10.6099C8.49194 10.7066 8.37074 10.7752 8.23804 10.8084L5.3964 11.5188C5.13921 11.5831 4.86714 11.5078 4.67969 11.3203C4.49223 11.1329 4.41687 10.8608 4.48117 10.6036L5.19158 7.76196C5.22476 7.62926 5.29337 7.50806 5.3901 7.41134L12.139 0.662444Z" />
          </svg>
          Reemplazar documento
        </button>

      </div>


    </div>-->
    <!-- SEPARADOR -->
    <div class="separador-tarjetas">
      <h4>Beneficiarios</h4>
      <span style="display: inline-block;vertical-align: middle;color: #326173;" title="Agregar dependientes">
                <i class="bi bi-person-add"></i> +{{dependentsList?.length}}
              </span>
      <!--<span style="margin-left: 8px; display: inline-block;vertical-align: middle;width: 145px;border-top: 1px solid #E1E1E1;"></span>
      <span class="badge pull-right">
                <i *ngIf="dependentsList?.length <= 0" class="bi bi-user"></i>
                <span style="cursor: pointer" *ngIf="dependentsList?.length > 0" >+{{dependentsList?.length}}</span>
              </span>-->
      <hr>
    </div>
    <!-- PREGUNTA PARA BENEFICIARIOS -->
    <div class="form-row">
      <div class="form-group">
        <label >¿Asignarás beneficiarios del asegurado en este momento?</label>
        <div class="">
          <div class="form-check form-check-inline">
            <input type="radio" id="rd_1" name="rd" class="form-check-input" value="Yes" onchange="showFormBeneficiarioRadioButton()"
                   (click)="activateDependentRegistrationInInsured()">
            <label class="custom-control-label" for="rd_1">Sí</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" id="rd_2" name="rd" class="form-check-input" value="No" checked onchange="hiddeFormBeneficiarioRadioButton()">
            <label class="custom-control-label" for="rd_2">No</label>
          </div>
        </div>
      </div>

    </div>

    <!-- FORM BENEFICIARIO -->
    <div id="formBeneficiarioRadio" class="d-none form-beneficiario-container">
      <div id="formBeneficiarioContenedor" class="form-beneficiario-container">
        <div class="form-beneficiario">
          <div class="form-row">
            <div class="form-group">
              <label for="nombreBeneficiario">Nombre del beneficiario</label>
              <input type="text" class="form-control ammiaAdmin-style" id="nombreBeneficiario" name="nombreBeneficiario"
                     [(ngModel)]="dataToSendDep.name" name="nameDep" required upperCaseText>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group">
              <label for="apellidoPaterno">Apellido paterno</label>
              <input type="text" class="form-control ammiaAdmin-style" id="apellidoPaterno" name="apellidoPaterno"
                     [(ngModel)]="dataToSendDep.pLastname" name="pLastnameDep" required upperCaseText>
            </div>
            <div class="form-group">
              <label for="apellidoMaterno">Apellido materno</label>
              <input type="text" class="form-control ammiaAdmin-style" id="apellidoMaterno" name="apellidoMaterno"
                     [(ngModel)]="dataToSendDep.sLastname" name="sLastnameDep" required upperCaseText>
            </div>

          </div>
          <div class="form-row">
            <div class="form-group form-group-sm">
              <label class="control-label">Género</label>
              <div class="form-group">
                <label class="radio-inline" *ngFor="let item of gendersCatalog">
                  <input type="radio" name="genderDep" [value]="item.genderId" [(ngModel)]="dataToSendDep.gender" required>
                  {{item.description}}
                </label>
              </div>
            </div>

          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Fecha de nacimiento</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
                <input type="text"
                       placeholder="00-00-0000"
                       class="form-control"
                       bsDatepicker
                       [bsConfig]="bsBirthdate"
                       [(ngModel)]="dataToSendDep.birthdate"
                       name="birthDateDep"
                       required>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>Fecha de alta</label>
              <div class="input-group">
                <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
                <input type="text"
                       placeholder="00-00-0000"
                       class="form-control"
                       bsDatepicker
                       [bsConfig]="bsEntryDate"
                       [(ngModel)]="dataToSendDep.entryDate"
                       name="entryDateDep"
                       required disabled>
              </div>
            </div>

          </div>
          <div class="form-row">
              <div class="form-group">
                <label>Parentesco con asegurado titular</label>
                <select class="form-control" [(ngModel)]="dataToSendDep.relationship" name="relationshipDep" required>
                  <option *ngFor="let item of relationshipList" [value]="item.kinshipId">{{item.name}}</option>
                </select>
              </div>
          </div>
        </div>
        <!-- Insertar aqui -->
      </div>
      <!-- BOTON AGREGAR BENEFICIARIO -->
      <div class="w-100 d-center mt-3">
        <button class="btn secundario " id="btnAgregarBeneficiario" type="button" aria-controls="offcanvasRight"
                (click)="addNewDependent()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z" />
          </svg>

          Agregar otro beneficiario
        </button>
      </div>

    </div>
    <div class=" offcanvas-footer d-center mt-3">
      <button class="btn primario" id="btnGenerarSolicitudCancel" type="button"
              (click)="invokeFunctionSaveRequestInsuredAdd()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5237 6.98805L10.2319 14.3279L7.52681 11.5317L5.99787 13.0463L9.17336 16.3084C9.29097 16.4249 9.52619 16.5415 9.76142 16.658C9.99664 16.7745 10.2319 16.7745 10.3495 16.7745C10.4671 16.7745 10.8199 16.7745 10.9375 16.658C11.1727 16.5415 11.2904 16.4249 11.5256 16.3084L19.1703 8.50262L17.5237 6.98805Z" />
          <path d="M20.5819 3.49515C18.3473 1.28155 15.2894 0 11.9963 0C8.82081 0 5.76293 1.28155 3.52833 3.49515C1.29372 5.82524 0 8.73786 0 12C0 15.1456 1.29372 18.1748 3.52833 20.5049C5.76293 22.7184 8.82081 24 11.9963 24C15.1718 24 18.2297 22.7184 20.4643 20.5049C22.6989 18.2913 23.9926 15.2621 23.9926 12C24.1102 8.73786 22.8165 5.82524 20.5819 3.49515ZM12.1139 21.6699C6.70382 21.6699 2.35222 17.2427 2.35222 11.8835C2.35222 6.52427 6.70382 2.09709 12.1139 2.09709C17.524 2.09709 21.8756 6.52427 21.8756 11.8835C21.8756 17.3592 17.524 21.6699 12.1139 21.6699Z" />
        </svg>

        Generar solicitud
      </button>
    </div>

  </form>

</div>
<!-- Por beneficiario -->
<div  id="porBeneficiario" class="d-none seleccionasteAlta"  *ngIf="responseService == 0 && dataRequest.typeAdd == 2">
  <form action="">

    <!--- Buscar cliente-->
    <div class="form-row mt-3">
      <div class="form-group">
        <label >Razón Social</label>
        <select class="form-select" id="razonSocialD" name="razonSocialD" [(ngModel)]="dataRequest.clientId"
                name="client" required >
          <option *ngFor="let item of clientList" [value]="item.clientId"
          >{{item.name}}</option>
        </select>
      </div>
    </div>
    <!-- Buscar asegurado titular -->
    <div class="form-row">
      <div class="form-group">
        <label>Buscar asegurado</label>
        <ng-template #customItemTemplate let-model="item" let-index="index">
          <p class="dmc">{{ model.name}}</p>
        </ng-template>
        <ng-template #customListTemplate let-matches="matches" let-itemTemplate="itemTemplate" let-query="query">
          <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
        <span *ngIf="match.isHeader()">
            {{match}}
        </span>
            <div class="global-search-option"
                 (click)="container.selectMatch(match, $event)">
              <ng-template [ngIf]="!match.isHeader()">
                <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: match.item, index: i, match: match, query: query}"></ng-template>
              </ng-template>
            </div>
          </ng-template>
        </ng-template>
        <div class="search-container d-between w-100">
          <!--  <input type="text" class=" form-control ammiaAdmin-style" placeholder="Busca por nombre de asegurado">
          <img src="../../assets/img/ammia/iconos/search.svg" alt="Ícono de Agregar tarea" class="search-icon">-->
          <input [(ngModel)]="searchItemSelected"
                 [typeaheadAsync]="true"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 typeaheadWaitMs="1000"
                 typeaheadOptionField="name"
                 placeholder="Buscar…"
                 class="form-control"
                 name="insured"
                 autocomplete="off"
                 [typeaheadItemTemplate]="customItemTemplate"
                 [optionsListTemplate]="customListTemplate"
                 #typeaheadDirective="bs-typeahead">

          <img src="../../assets/img/ammia/iconos/search.svg" alt="Ícono de Agregar tarea" class="search-icon">

          <div *ngIf="typeaheadLoading">Loading</div>

        </div>
      </div>
    </div>

    <!-- SEPARADOR -->
    <div class="separador-tarjetas">
      <h4>Selecciona la póliza maestra</h4>
      <hr>
    </div>
    <!-- Seleccionar poliza maestra -->
    <div class="form-row">
      <div class="form-group" *ngIf="policyList.length > 0">
        <div class="d-between">
          <div class="form-check form-check-inline mb-3" *ngFor="let p of policyList">
            <input class="form-check-input" type="radio" name="polizaMaestraSolicitud" id="polizaMaestraSolicitud {{p.idPolicy}}"
                   value="option{{p.policyId}}"
                   onchange="pruebaCheckbox(this)" >
            <label class="form-check-label poliza-maestra mx-0" for="polizaMaestraSolicitud {{p.idPolicy}}">

              <h4 class="">{{p.subranch | titlecase}} <br> {{p.numberPolicy}}</h4>
              <h5>{{p.insurance}}</h5>

              <div class="d-column-start mt-3">
                <div class="form-check form-check-inline" *ngFor="let c of p.policyCategoriesList">
                  <input class="form-check-input" type="checkbox" name="directoresSolicitud1" id="directoresSolicitud{{c.idCategory}}" onchange="radioButton(this)"
                         [(ngModel)]="c.selected" [ngModelOptions]="{standalone: false}" >
                  <label class="form-check-label  mx-0" for="directoresSolicitud{{c.idCategory}}">
                    <p class="">{{c.category | titlecase}}</p>
                  </label>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- SEPARADOR -->
    <div class="separador-tarjetas">
      <h4>Beneficiarios</h4>
      <span style="display: inline-block;vertical-align: middle;color: #326173;" title="Agregar dependientes">
                <i class="bi bi-person-add"></i> +{{dependentsList?.length}}
              </span>
      <hr>
    </div>

    <!-- FORM BENEFICIARIO -->
    <div>
      <div class="form-group form-group-sm">
        <label class="control-label">Nombre(s)</label>
        <input type="text" class="form-control" [(ngModel)]="dataToSend.name" name="name" required upperCaseText>
      </div>
      <div class="form-group form-group-sm">
        <label class="control-label">Apellido Paterno</label>
        <input type="text" class="form-control" [(ngModel)]="dataToSend.pLastname" name="pLastname" required upperCaseText>
      </div>
      <div class="form-group form-group-sm">
        <label class="control-label">Apellido Materno</label>
        <input type="text" class="form-control" [(ngModel)]="dataToSend.sLastname" name="sLastname" required upperCaseText>
      </div>
      <div class="form-group form-group-sm">
        <label class="control-label">Género</label>
        <div class="form-group">
          <label class="radio-inline" *ngFor="let item of gendersCatalog">
            <input type="radio" name="gender" [value]="item.genderId" [(ngModel)]="dataToSend.gender">
            {{item.description}}
          </label>
        </div>
      </div>
      <div class="form-group form-group-sm">
        <label class="control-label">Fecha de nacimiento</label>
        <div class="input-group">
          <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
          <input type="text"
                 placeholder="00-00-0000"
                 class="form-control"
                 bsDatepicker
                 [bsConfig]="bsBirthdate"
                 [(ngModel)]="dataToSend.birthdate"
                 name="entryDate"
                 required>
        </div>
      </div>
      <div class="form-group form-group-sm">
        <label class="control-label">Parentesco</label>
        <select class="form-control" [(ngModel)]="dataToSend.relationship" name="relationship" required>
          <option [value]="0" disabled [selected]="true">Seleccionar...</option>
          <option *ngFor="let item of relationshipList" [value]="item.kinshipId">{{item.name}}</option>
        </select>
      </div>
      <div class="form-group form-group-sm">
        <label class="control-label">Fecha de alta</label>
        <div class="input-group">
          <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
          <input type="text"
                 placeholder="00-00-0000"
                 class="form-control"
                 bsDatepicker
                 [bsConfig]="bsEntryDate"
                 [(ngModel)]="dataToSend.entryDate"
                 name="entryDate"
                 required>
        </div>
      </div>
    </div>
        <!-- Insertar aqui -->

      <!-- BOTON AGREGAR BENEFICIARIO -->
      <div class="w-100 d-center mt-3">
        <button class="btn secundario " id="btnAgregarBeneficiario2" type="button" aria-controls="offcanvasRight"
                (click)="addNewDependent()">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6ZM0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16C3.5816 16 0 12.4184 0 8ZM8 4C8.21217 4 8.41566 4.08429 8.56569 4.23431C8.71571 4.38434 8.8 4.58783 8.8 4.8V7.2H11.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8V11.2C8.8 11.4122 8.71571 11.6157 8.56569 11.7657C8.41566 11.9157 8.21217 12 8 12C7.78783 12 7.58434 11.9157 7.43431 11.7657C7.28429 11.6157 7.2 11.4122 7.2 11.2V8.8H4.8C4.58783 8.8 4.38434 8.71571 4.23431 8.56569C4.08429 8.41566 4 8.21217 4 8C4 7.78783 4.08429 7.58434 4.23431 7.43431C4.38434 7.28429 4.58783 7.2 4.8 7.2H7.2V4.8C7.2 4.58783 7.28429 4.38434 7.43431 4.23431C7.58434 4.08429 7.78783 4 8 4Z" />
          </svg>

          Agregar otro beneficiario
        </button>
      </div>


    <div class=" offcanvas-footer d-center mt-3">
      <button class="btn primario " id="btnGenerarSolicitudAddDepe" type="button"
              (click)="invokeFunctionSaveRequestInsuredAddDependent()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5237 6.98805L10.2319 14.3279L7.52681 11.5317L5.99787 13.0463L9.17336 16.3084C9.29097 16.4249 9.52619 16.5415 9.76142 16.658C9.99664 16.7745 10.2319 16.7745 10.3495 16.7745C10.4671 16.7745 10.8199 16.7745 10.9375 16.658C11.1727 16.5415 11.2904 16.4249 11.5256 16.3084L19.1703 8.50262L17.5237 6.98805Z" />
          <path d="M20.5819 3.49515C18.3473 1.28155 15.2894 0 11.9963 0C8.82081 0 5.76293 1.28155 3.52833 3.49515C1.29372 5.82524 0 8.73786 0 12C0 15.1456 1.29372 18.1748 3.52833 20.5049C5.76293 22.7184 8.82081 24 11.9963 24C15.1718 24 18.2297 22.7184 20.4643 20.5049C22.6989 18.2913 23.9926 15.2621 23.9926 12C24.1102 8.73786 22.8165 5.82524 20.5819 3.49515ZM12.1139 21.6699C6.70382 21.6699 2.35222 17.2427 2.35222 11.8835C2.35222 6.52427 6.70382 2.09709 12.1139 2.09709C17.524 2.09709 21.8756 6.52427 21.8756 11.8835C21.8756 17.3592 17.524 21.6699 12.1139 21.6699Z" />
        </svg>

        Generar solicitud
      </button>
    </div>

  </form>
</div>

<div class="w-100 d-column-center p-3 p-md-5" *ngIf="responseService == 2">
  <a href="javascript:;"   aria-controls="offcanvasRight">
    <dotlottie-player src="https://lottie.host/bb64e4a0-a834-4f97-8152-fba5e5234f5a/3vObIm0Ks5.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
  </a>

  <p class="">Se ha generado el reporte de tu solicitud. Un ejecutivo Surexs te contactará lo antes posible y te brindará atención.</p>


  <div class="d-center w-100 mt-5">
    <button class="btn primario" id="btnGenerarSolicitudVehiculo" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
      <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
      Entendido
    </button>
  </div>
</div>

<div class=""  *ngIf="responseService == 1">
  <div class="w-100 d-column-center">
    <a href="javascript:;"  aria-controls="offcanvasRight" >
      <dotlottie-player src="https://lottie.host/06fc7b75-f5d2-4524-86f8-fbbe96bf456a/VGiGPUrFSr.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
    </a>
    <div>
      <p class="solicitud-parrafo">Estamos validando tu solicitud. No cierres ni actualices esta venta. </p>
    </div>
  </div>
</div>
<div  *ngIf="responseService == 3">
  <a href="javascript:;"   aria-controls="offcanvasRight" >
    <dotlottie-player src="https://lottie.host/4c9dabcd-e6c6-41c2-84c9-7cf1b79890af/yn2rFJ1ICK.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
  </a>
  <div>
    <p class="solicitud-parrafo">Se ha producido un error al dar de alta la solicitud, por favor contacta al administrador.
    </p>
  </div>
  <div class="d-center w-100 mt-5">
    <button class="btn primario" id="btnGenerarSolicitudVehiculo33" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
      <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
      Cerrar
    </button>
  </div>
</div>
