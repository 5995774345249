import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {HomeComponent} from './home/home.component';
import {ApiService} from './api.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MainPanelComponent} from './main-panel/main-panel.component';
import {FooterComponent} from './footer/footer.component';
import {NavbarComponent} from './navbar/navbar.component';

import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {ProfileComponent} from './profile/profile.component';
import {NewIncidentComponent} from './new-incident/new-incident.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {NotificationMdlComponent} from './notification-mdl/notification-mdl.component';
import { RecoveryPasswordMdlComponent } from './recovery-password-mdl/recovery-password-mdl.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { ValidateCodeMdlComponent } from './validate-code-mdl/validate-code-mdl.component';
import {NgxUsefulSwiperModule} from 'ngx-useful-swiper';
// import {WebsocketService} from './websocket.service';
// import { ChatComponent } from './chat/chat.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { PolicyComponent } from './policy/policy.component';
import { IncidentComponent } from './incident/incident.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';
import { RequestComponent } from './request/request.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AdminPoliciesComponent } from './admin-policies/admin-policies.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AdminStaticsComponent } from './admin-statics/admin-statics.component';
import {RequestOptionsInterceptor} from './request-options.interceptor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {BsDatepickerModule, BsLocaleService} from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import {DatePipe, registerLocaleData} from '@angular/common';
import localeMx from '@angular/common/locales/es-MX';
import {CustomCurrencyPipe} from './custom.currencypipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AdminInsuredComponent } from './admin-insured/admin-insured.component';
import { MassiveRequestComponent } from './massive-request/massive-request.component';
import { MassiveRequestVehicleComponent } from './massive-request-vehicle/massive-request-vehicle.component';
import { VehicleRequestComponent } from './vehicle-request/vehicle-request.component';
import { AdminHomeConcessionaireComponent } from './admin-home-concessionaire/admin-home-concessionaire.component';
import { AdminHomeGeneralComponent } from './admin-home-general/admin-home-general.component';
import { QuoteDetailComponent } from './quote-detail/quote-detail.component';
import { QuoteFormComponent } from './quote-form/quote-form.component';
import { QuoteHistoryComponent } from './quote-history/quote-history.component';
import { DebtorlifeComponent } from './debtorlife/debtorlife.component';
import { MenuComponent } from './menu/menu.component';
import { DebtorlifeValidationComponent } from './debtorlife-validation/debtorlife-validation.component';
import {UpperCaseTextDirective} from './upper-case-text.directive';
import {UploadCertificateComponent} from './upload-certificate/upload-certificate.component';
import { RequestTempDetailComponent } from './request-temp-detail/request-temp-detail.component';
import { NewRequestCarComponent } from './new-request-car/new-request-car.component';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {NgSelectModule} from '@ng-select/ng-select';
import {UiSwitchModule} from 'ngx-ui-switch';
import {PushNotificationModule} from 'ng-push-notification';
import { DashboardAmmiaComponent } from './dashboard-ammia/dashboard-ammia.component';
import { NavbarAmmiaComponent } from './navbar-ammia/navbar-ammia.component';
import { MenuAmmiaComponent } from './menu-ammia/menu-ammia.component';
import { DashboardBenefitsComponent } from './dashboard-benefits/dashboard-benefits.component';
import { DashboardCarsComponent } from './dashboard-cars/dashboard-cars.component';
import { DashboardDamagesComponent } from './dashboard-damages/dashboard-damages.component';
import { DashboardPaymentsComponent } from './dashboard-payments/dashboard-payments.component';
import { RequestAmmiaComponent } from './request-ammia/request-ammia.component';
import { EventAmmiaBenefitsComponent } from './event-ammia-benefits/event-ammia-benefits.component';
import { EventAmmiaCarComponent } from './event-ammia-car/event-ammia-car.component';
import { EventAmmiaDamageComponent } from './event-ammia-damage/event-ammia-damage.component';
import { GraphAmmiaBenefitsComponent } from './graph-ammia-benefits/graph-ammia-benefits.component';
import { GraphAmmiaCarComponent } from './graph-ammia-car/graph-ammia-car.component';
import { IncidentAmmiaComponent } from './incident-ammia/incident-ammia.component';
import { AdminAmmiaInsuredComponent } from './admin-ammia-insured/admin-ammia-insured.component';
import { ReportAmmiaComponent } from './report-ammia/report-ammia.component';
import { InsuredDetailAmmiaComponent } from './insured-detail-ammia/insured-detail-ammia.component';
import { IncidentDetailAmmiaComponent } from './incident-detail-ammia/incident-detail-ammia.component';
import { RequestDetailAmmiaComponent } from './request-detail-ammia/request-detail-ammia.component';
import { PolicyDetailAmmiaComponent } from './policy-detail-ammia/policy-detail-ammia.component';
import { RequestGeneralAmmiaComponent } from './request-general-ammia/request-general-ammia.component';
import { RequestCancelledAmmiaComponent } from './request-cancelled-ammia/request-cancelled-ammia.component';
import { RequestAddAmmiaComponent } from './request-add-ammia/request-add-ammia.component';
import { RequestMassImportAmmiaComponent } from './request-mass-import-ammia/request-mass-import-ammia.component';
import { RequestDeleteAmmiaComponent } from './request-delete-ammia/request-delete-ammia.component';
import { RequestProcessMassImportAmmiaComponent } from './request-process-mass-import-ammia/request-process-mass-import-ammia.component';
import {ResponseOptionsInterceptor} from "./response-options.interceptor";
import { RequestGeneralCarAmmiaComponent } from './request-general-car-ammia/request-general-car-ammia.component';
import { RequestCancelledCarAmmiaComponent } from './request-cancelled-car-ammia/request-cancelled-car-ammia.component';
import { RequestAddCarAmmiaComponent } from './request-add-car-ammia/request-add-car-ammia.component';
import { RequestGeneralDamageAmmiaComponent } from './request-general-damage-ammia/request-general-damage-ammia.component';

registerLocaleData(localeMx, 'es-MX');
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SidebarComponent,
        HomeComponent,
        MainPanelComponent,
        FooterComponent,
        NavbarComponent,
        ProfileComponent,
        NewIncidentComponent,
        NotificationMdlComponent,
        RecoveryPasswordMdlComponent,
        RecoveryComponent,
        ValidateCodeMdlComponent,
        // ChatComponent,
        NewRequestComponent,
        PolicyComponent,
        IncidentComponent,
        AdminHomeComponent,
        AdminReportsComponent,
        RequestComponent,
        AdminPoliciesComponent,
        AdminStaticsComponent,
        CustomCurrencyPipe,
        AdminInsuredComponent,
        MassiveRequestComponent,
        MassiveRequestVehicleComponent,
        VehicleRequestComponent,
        AdminHomeConcessionaireComponent,
        AdminHomeGeneralComponent,
        QuoteDetailComponent,
        QuoteFormComponent,
        QuoteHistoryComponent,
        DebtorlifeComponent,
        MenuComponent,
        DebtorlifeValidationComponent,
        UpperCaseTextDirective,
        UploadCertificateComponent,
        RequestTempDetailComponent,
        NewRequestCarComponent,
        DashboardAmmiaComponent,
        NavbarAmmiaComponent,
        MenuAmmiaComponent,
        DashboardBenefitsComponent,
        DashboardCarsComponent,
        DashboardDamagesComponent,
        DashboardPaymentsComponent,
        RequestAmmiaComponent,
        EventAmmiaBenefitsComponent,
        EventAmmiaCarComponent,
        EventAmmiaDamageComponent,
        GraphAmmiaBenefitsComponent,
        GraphAmmiaCarComponent,
        IncidentAmmiaComponent,
        AdminAmmiaInsuredComponent,
        ReportAmmiaComponent,
        InsuredDetailAmmiaComponent,
        IncidentDetailAmmiaComponent,
        RequestDetailAmmiaComponent,
        PolicyDetailAmmiaComponent,
        RequestGeneralAmmiaComponent,
        RequestCancelledAmmiaComponent,
        RequestAddAmmiaComponent,
        RequestMassImportAmmiaComponent,
        RequestDeleteAmmiaComponent,
        RequestProcessMassImportAmmiaComponent,
        RequestGeneralCarAmmiaComponent,
        RequestCancelledCarAmmiaComponent,
        RequestAddCarAmmiaComponent,
        RequestGeneralDamageAmmiaComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        NgxUsefulSwiperModule,
        PaginationModule.forRoot(),
        TypeaheadModule.forRoot(),
        TabsModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        InfiniteScrollModule,
        CollapseModule.forRoot(),
        NgSelectModule,
        UiSwitchModule,
        PushNotificationModule.forRoot()
    ],
  entryComponents: [
    NewIncidentComponent,
    NotificationMdlComponent,
    RecoveryPasswordMdlComponent,
    ValidateCodeMdlComponent
  ],
  providers: [ApiService,
    // WebsocketService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestOptionsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseOptionsInterceptor,
      multi: true,
    },
    DatePipe,
    { provide: LOCALE_ID, useValue: 'es-MX' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(localeService: BsLocaleService) {
    defineLocale('es', esLocale);
    localeService.use('es');
  }
}
