/**
 * @fileoverview Define el comportamiento de todas las peticiones para el registro de Solicitudes
 * @version 1.0.0
 * @author Juan Ramírez Pavía
 */

import { Component, OnInit } from '@angular/core';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {ToastrService} from 'ngx-toastr';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {last, mergeMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DatePipe} from '@angular/common';
import {Documents} from '../upload-certificate/documents';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-new-request',
  templateUrl: './new-request.component.html',
  styleUrls: ['./new-request.component.css']
})
export class NewRequestComponent implements OnInit {
  displayForm = true;
  attachDependents = false;
  // Variables para el multiselect de Pólizas ->
  dropdownSettingsPolicies = {};
  policiesSelectedItems: any = [];

  // Variables para el multiselect de Dependientes ->
  dropdownSettingsDependents = {};
  dependentsSelectedItems: any = [];

  // Objetos para los DatePickers ->
  bsBirthdate: Partial<BsDatepickerConfig>;
  bsEntryDate: Partial<BsDatepickerConfig>;
  bsLeavingDate: Partial<BsDatepickerConfig>;

  policiesList: any = []; // Almacena la lista de pólizas retornada por el servicio
  // Almacena la lista de dependientes retornada por el servicio || Almacena la lista de dependientes a dar de alta(alta de Dependiente)
  dependentsList: any = [];
  relationshipList: any = []; // Almaccena la lista de parentescos retornada por el servicio
  dataToSend: any = {}; // Almacena los datos de envío
  selected: boolean;
  dataToSendDep: any = {}; // Almacena los datos del dependiente dentro del apartado de Asegurados

  lclRequestType: number; // Almacena el tipo de Solicitud que se enviará
  disabledSalary: boolean; // Bandera para Habilitar/Inhabilitar el input para Salario

  // Variables para typeahead (búsqueda de asegurados) ->
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  lclInsuredSelected: any = {};

  gendersCatalog: any = [];
  currentRoleId: number;
  statsend: boolean;

  // Variables para Solicitudes
  uploadedFilesAntique: Array<Documents> = []; // Documentos del cliente
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];

  constructor(private api: ApiService,
              public shared: SharedService,
              private toastr: ToastrService,
              private datePipe: DatePipe) {

    this.currentRoleId = Number(localStorage.getItem('roleId'));
    /**
     * Observable para monitorear los cambios en typeahead de Asegurados
     */
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getInsuredAsObservable(token))
    );
  }

  ngOnInit() {
    // Inicializamos configuración para dropdown de Pólizas ->
    this.dropdownSettingsPolicies = {
      singleSelection: false,
      idField: 'categoryId',
      textField: 'number',
      selectAllText: 'Todas',
      unSelectAllText: 'Ninguna',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No se encontraron pólizas',
      searchPlaceholderText: 'Buscar'
    };
    // Inicializamos configuración para dropdown de Dependientes ->
    this.dropdownSettingsDependents = {
      singleSelection: false,
      idField: 'dependentId',
      textField: 'name',
      selectAllText: 'Todos',
      unSelectAllText: 'Ninguno',
      itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No se encontraron dependientes',
      searchPlaceholderText: 'Buscar'
    };
    // Iniciamos configuración para DatePicker de Fecha de Nacimiento ->
    this.bsBirthdate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      maxDate: new Date()
    });
    // Iniciamos configuración para DatePicker de Fecha de Alta ->
    const currentDateMinus30Days = new Date();
    currentDateMinus30Days.setDate(currentDateMinus30Days.getDate() - 30);
    this.bsEntryDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: currentDateMinus30Days
    });
    // Iniciamos configuración para DatePicker de Fecha de Baja ->
    this.bsLeavingDate = Object.assign({}, {
      adaptivePosition: true,
      containerClass: 'theme-dark-blue',
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: currentDateMinus30Days,
    });

    this.disabledSalary = true; // Inhabilitamos el input para Salario

    // Inicializamos el objeto de datos de envio
    this.dataToSend = {
      upType: 0, // Tipo de alta Asegurado
    };

    this.selectRequestType(2); // Por default mostramos el registro de solitud General
    this.statsend = false;
  }

  private invokeServiceForGetGendersCatalog() {
    this.api.getGendersCatalog()
      .subscribe(
        response => {
          this.gendersCatalog = response;
          this.dataToSend.gender = this.gendersCatalog[0].genderId;
          this.dataToSendDep.gender = this.gendersCatalog[0].genderId;
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Método para cambiar variables o invocar servicios según el tipo de registro que se realizará
   *
   * @param item [number] Identificador el tipo de Solicitud
   * 0 = Alta
   * 1 = Baja
   * 2 = General
   */
  public selectRequestType(item) {
    this.lclRequestType = item;
    this.dataToSend = {};
    this.policiesSelectedItems = [];
    this.policiesList = [];
    this.searchItemSelected = undefined;
    this.lclInsuredSelected = {};
    switch (this.lclRequestType) {
      case 0:
        this.dataToSend.upType = 0;
        this.invokeServiceForGetPolicies();
        this.invokeServiceForGetGendersCatalog();
        break;
      case 1:
        this.dataToSend.upType = 0;
        break;
      case 2:
        this.dataToSend.upType = 0;
        this.invokeServiceForGetPolicies();
        this.getUserProfile();
        break;
    }
  }

  /**
   * Método para cambiar variables o invocar servicios según el tipo de Solicitud y tipo de persona
   *
   * valores para variable :lclRequestType
   * 0 = Alta
   * 1 = Baja
   * 2 = General
   *
   * @param item [number] Identificador para el tipo de persona
   * 0 = Asegurado
   * 1 = Dependiente
   *
   */
  public changeUpType(item) {
    this.dataToSend = {};
    this.dataToSend.upType = item;
    this.policiesSelectedItems = [];
    this.dependentsSelectedItems = [];
    this.policiesList = [];
    this.searchItemSelected = undefined;
    this.lclInsuredSelected = {};
    switch (this.lclRequestType) {
      case 0:
        setTimeout( () => {
          switch (this.dataToSend.upType) {
            case 0:
              this.invokeServiceForGetPolicies();
              this.invokeServiceForGetGendersCatalog();
              break;
            case 1:
              this.invokeServiceForGetRelationshipCatalog();
              this.invokeServiceForGetGendersCatalog();
              break;
          }
        }, 500);
        break;
      case 1:
        break;
    }
  }

  public activateDependentRegistrationInInsured() {
    this.attachDependents = true;
    this.invokeServiceForGetRelationshipCatalog();
    this.dataToSendDep.entryDate = this.dataToSend.entryDate;
  }

  private getUserProfile() {
    this.api.getUserProfile()
      .then(
        (data: any) => {
          this.dataToSend.name = data.name;
          this.dataToSend.pLastname = data.primaryLastName;
          this.dataToSend.sLastname = data.secondaryLastName;
        }, error => {
          console.log(error);
        }
      );
  }

  /**
   * Método para obtener la lista de pólizas
   * 1.- Sí el tipo de Solicitud es 'Alta' indicado por la variable :lclRequestType = 0
   *    1.1.- Sí el tipo de 'Alta' es 'Asegurado' indicado por la propiedad :dataToSend.upType = 0
   *        la lista de pólizas se obtiene a través del servicio REST @getPoliciesMin
   *        se almacena la respuesta en la variable de tipo array :policiesList
   *    1.2.- Sí el tipo de 'Alta' es 'Dependiente' indicado por la propiedad :dataToSend.upType = 1
   *        la lista de pólizas se obtiene a través del servicio REST @getPoliciesMinByInsuredId
   *        se almacena la respuesta en la variable de tipo array :policiesList
   *
   * 2.- Sí el tipo de Solicitud es 'Baja' indicado por la variable :lclRequestType = 1
   *    la lista de pólizas se obtiene a través del servicio REST @getPoliciesMinByInsuredId
   *    se almacena la respuesta en la variable de tipo array :policiesList
   *
   * @param insuredId [number] Id del Asegurado para filtrar las pólizas
   */
  private invokeServiceForGetPolicies(insuredId?) {
    switch (this.lclRequestType) {
      case 0:
        switch (this.dataToSend.upType) {
          case 0:
            this.api.getPoliciesClientMin()
              .subscribe(
                (data) => {
                  this.policiesList = data;
                }, error => {
                  console.error(error.status);
                  console.error(error.statusText);
                  console.error(error.message);
                }
              );
            break;
          case 1:
            this.policiesList = [];
            this.api.getPoliciesClientMinByInsuredId(insuredId)
              .subscribe(
                response => {
                  this.policiesList = response;
                }, error => {
                  console.error(error);
                }
              );
            break;
        }
        break;
      case 1:
        this.policiesList = [];
        this.api.getPoliciesClientMinByInsuredId(insuredId)
          .subscribe(
            response => {
              this.policiesList = response;
            }, error => {
              console.error(error);
            }
          );
        break;
      case 2:
        this.api.getPoliciesClientMin()
          .subscribe(
            (data) => {
              this.policiesList = data;
            }, error => {
              console.error(error.status);
              console.error(error.statusText);
              console.error(error.message);
            }
          );
        break;
    }
  }

  /**
   * Método para obtener una lista de 'Parentescos'
   * la lista se obtiene a través del servicio REST @getRelationshipCat
   *
   * 1.- Invocamos el servicio REST y almacenamos la respuesta en nuestra variable de tipo array :relationshipList
   */
  private invokeServiceForGetRelationshipCatalog() {
    this.api.getRelationshipCat()
      .subscribe(
        response => {
          this.relationshipList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Método para obtener una lista de 'Dependientes' de un 'Asegurado'
   * la lista se obtiene a través del servicio REST @getDependentsByInsuredId
   *
   * 1.- Invocamos el servicio REST y almacenamos la respuesta en nuestra variable de tipo array :dependentsList
   *
   * @param insuredId [number] Id del 'Asegurado' para filtrar los 'Dependientes'
   */
  private invokeServiceForGetDependentsByInsuredId(insuredId) {
    this.api.getDependentsByInsuredId(insuredId)
      .subscribe(
        response => {
          this.dependentsList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  /**
   * Método para habilitar/inhabilitar input para 'Salario'
   * para habilitar el input de 'Salario' debe de haber por lo menos una póliza de tipo 'Vida' (typePolicy = 10) seleccionada
   *
   * 1.- Inhabilitamos el input 'Salario'
   * 2.- Iteramos el arreglo :policiesSelectedItems que contiene las pólizas seleccionadas por el usuario (iteracion A)
   * 3-. Buscamos la iteración A en el arreglo que contiene todas las pólizas retornadas por el servicio REST
   *        almacenadas en la variable de tipo array :policiesList y retornamos la coincidencia en la constante :policy
   * 4.- Verificamos sí la constante :policy es una póliza de tipo 'Vida' :typePolicy = 10
   * 5.- Sí es de tipo 'Vida' habilitamos el input 'Salario'
   *
   * El arreglo :policiesSelectedItems no contiene la informacion completa de las pólizas por eso buscamos sus elementos
   * en el arreglo :policiesList que contiene la información completa de las pólizas
   */
  public changeStatusOnSalaryField() {
    this.disabledSalary = true;
    setTimeout(() => {
      try {
        this.policiesSelectedItems.forEach(item => {
          const policy = this.policiesList.filter(x => x.policyId === item.policyId);
          if (policy[0].typePolicy === 10) {
            this.disabledSalary = false;
            throw new Error('break');
          } else {
            this.disabledSalary = true;
          }
        });
      } catch (e) { }
    }, 500);
  }

  /*validateDuplicatePolice(policiesSelected: any): boolean {
    const policySelected = [];
    let foundedPolicyRepetead = false;

    for (const item of policiesSelected) {
      if (policySelected.length > 1 && policySelected.includes(item.policyId)) {
         this.toastr.warning('Solo puede asignarse una categoría por póliza, favor de modificar las pólizas seleccionadas');
         foundedPolicyRepetead = true;
         this.statsend = false;
         return foundedPolicyRepetead;
      } else {
        policySelected.push(item.policyId);
      }
    }
    return foundedPolicyRepetead;
  }*/

  validateDuplicatePolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let foundedPolicyRepetead = false;
    for (const policySubranchCategory of policySubranchCategories) {
      let policy;
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          if (policy === policySubranchCategory.idPolicy) {
            this.toastr.warning('Solo puede asignarse una categoría por póliza, favor de modificar las pólizas seleccionadas');
            foundedPolicyRepetead = true;
            return;
          } else {
            policy = policySubranchCategory.idPolicy;
          }

          policy = policySubranchCategory.idPolicy;
        }
      }
    }
    return foundedPolicyRepetead;
  }

  validateSelectedPolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let isSelected = false;
    for (const policySubranchCategory of policySubranchCategories) {
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          isSelected = true;
        }
      }
    }
    return isSelected;
  }

  /**
   * Cambia el elemento typeahead para búsqueda de 'Asegurados' a modo 'Loading'
   * @param e
   */
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  /**
   * Método para efectuar acciones cuando se ha seleccionado un elemento del typeahead de 'Asegurados'
   *
   * 1.- Almacenamos el elemento selecccionado en la variable :lclInsuredSelected
   * 2.- Sí el tipo de Solicitud es 'Alta' indicado por la variable :lclRequestType = 0
   *     invocamos la funcion @invokeServiceForGetPolicies() y le pasamos como parametro el Id del Asegurado
   * 3.- Sí el tipo de Solicitud es 'Baja' indicado por la variable :lclRequestType = 1
   *    3.1.- Sí el tipo de 'Baja' es 'Asegurado' indicado por la propiedad :dataToSend.upType = 0
   *          invocamos la funcion @invokeServiceForGetPolicies() y le pasamos como parametro el Id del Asegurado
   *    3.2.- Sí el tipo de 'Baja' es 'Dependiente' indicado por la propiedad :dataToSend.upType = 1
   *          invocamos la funcion @invokeServiceForGetDependentsByInsuredId() y le pasamos como parametro el Id del Asegurado
   *
   * @param e Elemento seleccionado
   */
  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.lclInsuredSelected = e.item;
    switch (this.lclRequestType) {
      case 0:
        this.invokeServiceForGetPolicies(this.lclInsuredSelected.userId);
        break;
      case 1:
        switch (this.dataToSend.upType) {
          case 0:
            this.invokeServiceForGetPolicies(this.lclInsuredSelected.userId);
            break;
          case 1:
            this.invokeServiceForGetPolicies(this.lclInsuredSelected.userId);
            this.invokeServiceForGetDependentsByInsuredId(this.lclInsuredSelected.userId);
            break;
        }
        break;
    }
  }

  /**
   * Función para recuperar las coincidencias de la búsqueda en el typeahead de 'Asegurado'
   *
   * 1.- Invocamos el servicio REST @getFinderInsured y retornamos la respuesta
   * @param token [string] Filtro para realizar la búsqueda
   */
  getInsuredAsObservable(token: string): Observable<any> {
    return this.api.getFinderInsured(token).pipe();
  }

  /**
   * Método par agregar un nuevo dependiente a la lista
   *
   * 1.- Recuperamos los datos del dependiente y los agregamos a lista
   * 2.- Limpiamos el formulario
   */
  public addNewDependent() {
    let objToSend01 = {};
    console.log(this.dataToSend.upType);
    switch (this.dataToSend.upType) { // this.lclRequestType
      case 0:
        console.log(this.dataToSendDep);
        if (this.dataToSendDep.relationship === undefined
            || this.dataToSendDep.name === undefined
            || this.dataToSendDep.pLastname === undefined
            || this.dataToSendDep.sLastname === undefined
            || this.dataToSendDep.birthdate === undefined
            || this.dataToSendDep.entryDate === undefined
            || this.dataToSendDep.gender === undefined) {
          this.toastr.warning('Por favor llene todos los campos requeridos');
          return;
        }

        if (this.dataToSendDep.relationship === '2' && !this.onCalculateAgeDependent(this.dataToSendDep.birthdate)) {
          return;
        }
        setTimeout(() => {
          objToSend01 = {
            name: this.dataToSendDep.name,
            firstName: this.dataToSendDep.pLastname,
            lastName: this.dataToSendDep.sLastname,
            birthDate: this.datePipe.transform(this.dataToSendDep.birthdate, 'yyyy-MM-dd'),
            kinshipId: parseInt(this.dataToSendDep.relationship, 10),
            // kinship: relationShip0.name,
            userId: 0,
            startDate: this.datePipe.transform(this.dataToSendDep.entryDate, 'yyyy-MM-dd'),
            gender: this.dataToSendDep.gender,
          };

          this.dependentsList.push(objToSend01);

          this.dataToSendDep.name = undefined;
          this.dataToSendDep.pLastname = undefined;
          this.dataToSendDep.sLastname = undefined;
          this.dataToSendDep.relationship = undefined;
          this.dataToSendDep.birthDate = undefined;
        }, 10);
        break;
      case 1:
        if (this.dataToSend.relationship === '2' && !this.onCalculateAgeDependent(this.dataToSend.birthdate)) {
          return;
        }
        setTimeout(() => {
          objToSend01 = {
            name: this.dataToSend.name,
            firstName: this.dataToSend.pLastname,
            lastName: this.dataToSend.sLastname,
            birthDate: this.datePipe.transform(this.dataToSend.birthdate, 'yyyy-MM-dd'),
            kinshipId: parseInt(this.dataToSend.relationship,  10),
            userId: this.lclInsuredSelected.userId,
            startDate: this.datePipe.transform(this.dataToSend.entryDate, 'yyyy-MM-dd'),
            gender: this.dataToSend.gender,
            policyList: undefined
          };

          this.dependentsList.push(objToSend01);

          this.dataToSend.name = undefined;
          this.dataToSend.pLastname = undefined;
          this.dataToSend.sLastname = undefined;
          this.dataToSend.relationship = undefined;
          this.dataToSendDep.birthDate = undefined;
          this.uploadedFilesAntique = [];
        }, 10);
        break;
    }
  }

  /**
   * Método para realizar el envío del regsitrso de 'Solicitud'
   *
   * 1.- Sí el tipo de Solicitud es 'Alta' indicado por la variable :lclRequestType = 0
   *    1.1.- Sí el tipo de 'Alta' es 'Asegurado' indicado por la propiedad :dataToSend.upType = 0
   *          construimos el objeto con las propiedades especificas para este tipo de Solicitud
   *          enviamos el objeto al servicio REST @postRequestInsuredAdd
   *    1.2.- Sí el tipo de 'Alta' es 'Dependiente' indicado por la propiedad :dataToSend.upType = 1
   *          construimos la lista con las propiedades especificas para este tipo de Solicitud
   *          enviamos la lista al servicio REST @postRequestDependentAdd
   *
   * 2.- Sí el tipo de Solicitud es 'Baja' indicado por la variable :lclRequestType = 1
   *    2.1.- Sí el tipo de 'Baja' es 'Asegurado' indicado por la propiedad :dataToSend.upType = 0
   *          construimos el objeto con las propiedades especificas para este tipo de Solicitud
   *          enviamos el objeto al servicio REST @postRequestInsuredRemove
   *    2.2.- Sí el tipo de 'Baja' es 'Dependiente' indicado por la propiedad :dataToSend.upType = 1
   *          construimos el objeto con las propiedades especificas para este tipo de Solicitud
   *          enviamos el objeto al servicio REST @postRequestDependentRemove
   *
   * 3.- Sí el tipo de Solicitud es 'General' indicado por la variable :lclRequestType = 2
   *     construimos el objeto con las propiedades especificas para este tipo de Solicitud
   *     enviamos el objeto al servicio REST @postRequestData
   */
   sendRequestData() {
    if (this.statsend) {
      return;
    }
    this.statsend = true;
    switch (this.lclRequestType) {
      case 0:
        const policiesList0 = [];
        /*this.policiesSelectedItems.map(x => {
          this.policiesList.map(policies => {
            if (policies.categoryId === x.categoryId) {
              policiesList0.push({
                policyId: policies.policyId,
                categoryId: policies.categoryId
              });
            }
          });
        });*/

        if (this.validateDuplicatePolice(this.policiesList)) {
          this.statsend = false;
          return;
        }
        if (!this.validateSelectedPolice(this.policiesList)) {
          this.toastr.warning('Es necesario seleccionar al menos una categoría');
          this.statsend = false;
          return;
        }
        switch (this.dataToSend.upType) {
          case 0:
            console.log(this.dataToSendDep);

            if (this.dataToSendDep.relationship === '2' && !this.onCalculateAgeDependent(this.dataToSendDep.birthdate)) {
              this.statsend = false;
              return;
            }

            if (this.dataToSendDep.name !== undefined
                && this.dataToSendDep.pLastname !== undefined
                && this.dataToSendDep.sLastname !== undefined
                && this.dataToSendDep.birthdate !== undefined
                && this.dataToSendDep.relationship !== undefined
                && this.dataToSendDep.gender !== undefined) {
              // setTimeout(() => {
                const lastDependent = {
                  name: this.dataToSendDep.name,
                  firstName: this.dataToSendDep.pLastname,
                  lastName: this.dataToSendDep.sLastname,
                  birthDate: this.datePipe.transform(this.dataToSendDep.birthdate, 'yyyy-MM-dd'),
                  kinshipId: parseInt(this.dataToSendDep.relationship,  10),
                  userId: 0,
                  startDate: this.datePipe.transform(this.dataToSendDep.entryDate, 'yyyy-MM-dd'),
                  gender: this.dataToSendDep.gender,
                };
                this.dependentsList.push(lastDependent);

            }

            const objToSend00 = {
              name: this.dataToSend.name,
              firstName: this.dataToSend.pLastname,
              lastName: this.dataToSend.sLastname,
              email: this.dataToSend.email.toLowerCase().trim(),
              number: this.dataToSend.employeeNumber,
              birthDate: this.datePipe.transform(this.dataToSend.birthdate, 'yyyy-MM-dd'),
              startDate: this.datePipe.transform(this.dataToSend.entryDate, 'yyyy-MM-dd'),
              salary: this.dataToSend.salary,
              gender: this.dataToSend.gender,
              policySubranchCategories: this.policiesList,
              policyList: [],
              dependetList: this.dependentsList,
              baseAntique: this.uploadedFilesAntique.length === 0 ? '' : this.uploadedFilesAntique[0].base64,
              nameFileAntique: this.uploadedFilesAntique.length === 0 ? '' : this.uploadedFilesAntique[0].name
            };


            this.api.postRequestInsuredAdd(objToSend00)
              .subscribe(
                response => {
                  this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
                  this.shared.fUpdateRequests.next(true);
                  this.shared.fCloseSidebar();
                }, error => {
                  if (error.status === 500) {
                    if (error.error.message !== undefined) {
                      this.toastr.warning(error.error.message, 'Notificación');
                      this.dependentsList = [];
                    } else {
                      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                  } else {
                    this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                  }
                  this.statsend = false;
                }
              );
            break;
          case 1:
            const listToSend01 = [];

            if (this.dataToSend.relationship === '2' && !this.onCalculateAgeDependent(this.dataToSend.birthdate)) {
              this.statsend = false;
              return;
            }
            if (!this.validateSelectedPolice(this.policiesList)) {
              this.toastr.warning('Es necesario seleccionar al menos una categoría');
              this.statsend = false;
              return;
            }
            for (const dependent of this.dependentsList) {
              delete dependent.kinship;
              dependent.policyList = [];
              dependent.policySubranchCategories = this.policiesList;
              listToSend01.push(dependent);
            }
            listToSend01.push({
              name: this.dataToSend.name,
              firstName: this.dataToSend.pLastname,
              lastName: this.dataToSend.sLastname,
              birthDate: this.datePipe.transform(this.dataToSend.birthdate, 'yyyy-MM-dd'),
              kinshipId: Number(this.dataToSend.relationship),
              userId: this.lclInsuredSelected.userId,
              startDate: this.datePipe.transform(this.dataToSend.entryDate, 'yyyy-MM-dd'),
              gender: this.dataToSend.gender,
              policyList: [],
              policySubranchCategories: this.policiesList
            });

            this.api.postRequestDependentAdd(listToSend01)
              .subscribe(
                response => {
                  this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
                  this.shared.fUpdateRequests.next(true);
                  this.shared.fCloseSidebar();
                }, error => {
                  console.error(error);
                  if (error.status === 500) {
                    if (error.error.message !== undefined) {
                      this.toastr.warning(error.error.message, 'Notificación');
                      this.dependentsList = [];
                    } else {
                      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                  } else {
                    this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                  }
                  this.statsend = false;
                }
              );
            break;
        }
        break;
      case 1:
        switch (this.dataToSend.upType) {
          case 0:
            /*let policiesList10 = [];
            this.policiesSelectedItems.map(x => {
              this.policiesList.map(policies => {
                if (policies.categoryId === x.categoryId) {
                  policiesList10.push({
                    policyId: policies.policyId,
                    categoryId: policies.categoryId
                  });
                }
              });
            });*/
            if (!this.validateSelectedPolice(this.policiesList)) {
              this.toastr.warning('Es necesario seleccionar al menos una categoría');
              this.statsend = false;
              return;
            }
            const objToSend10 = {
              userId: this.lclInsuredSelected.userId,
              startDate: this.datePipe.transform(this.dataToSend.leavingDate, 'yyyy-MM-dd'),
              policyList: [],
              policySubranchCategories: this.policiesList,
            };

            this.api.postRequestInsuredRemove(objToSend10)
              .subscribe(response => {
                this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
                this.shared.fUpdateRequests.next(true);
                this.shared.fCloseSidebar();
              },  error => {
                console.error(error);
                if (error.status === 500) {
                  if (error.error.message !== undefined) {
                    this.toastr.warning(error.error.message, 'Notificación');
                  } else {
                    this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                  }
                } else {
                  this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                }
                this.statsend = false;
              }
            );
            break;
          case 1:
            let policiesList11 = [];
           /*  this.policiesSelectedItems.map(x => {
              this.policiesList.map(policies => {
                if (policies.categoryId === x.categoryId) {
                  policiesList11.push({
                    policyId: policies.policyId,
                    categoryId: policies.categoryId
                  });
                }
              });
            });*/
            if (!this.validateSelectedPolice(this.policiesList)) {
              this.toastr.warning('Es necesario seleccionar al menos una categoría');
              this.statsend = false;
              return;
            }
            const objToSend11 = this.dependentsSelectedItems.map(x => (
                {
                  userId: this.lclInsuredSelected.userId,
                  startDate: this.datePipe.transform(this.dataToSend.leavingDate, 'yyyy-MM-dd'),
                  dependentId: x.dependentId,
                  policyList: [],
                  policySubranchCategories: this.policiesList
                }
              ));

            this.api.postRequestDependentRemove(objToSend11)
              .subscribe(
                response => {
                  this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
                  this.shared.fUpdateRequests.next(true);
                  this.shared.fCloseSidebar();
                }, error => {
                  console.error(error);
                  if (error.status === 500) {
                    if (error.error.message !== undefined) {
                      this.toastr.warning(error.error.message, 'Notificación');
                    } else {
                      this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                    }
                  } else {
                    this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
                  }
                  this.statsend = false;
                }
              );
            break;
        }
        break;
      case 2:
        const policiesListGeneral = this.policiesSelectedItems.map(x => ({policyId: x.policyId}));
        const objToSend2 = {
          policySubranchCategories: this.policiesList,
          subject: this.dataToSend.subject,
          description: this.dataToSend.description,
          isincumbent: this.dataToSend.incumbent,
          employedNum: this.dataToSend.employeeNumber
        };
        this.api.postRequestData(objToSend2)
          .then(
            (response) => {
              this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
              this.shared.fUpdateRequests.next(true);
              this.shared.fCloseSidebar();
            }, error => {
              console.error(error.status);
              console.error(error.statusText);
              console.error(error.message);
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
              this.statsend = false;
            }
          );
        break;
    }
  }

  onCalculateAge(birthdate: any): boolean {
    let timeDiff;

    timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());

    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);

    if (Number(age) > 18 ) {
      return true;
    } else {
      return false;
    }
    console.log(age);
  }

  onCalculateAgeDependent(birthdate: any): boolean {
    let timeDiff;
    timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());

    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    let isAgeAllowed = true;

    this.policiesSelectedItems.map(x => {
      this.policiesList.map(policies => {
        if (policies.categoryId === x.categoryId) {
          const maxAgeLimit = policies.ageMax;
          if (maxAgeLimit !== 0 && Number(age) > maxAgeLimit ) {
            this.toastr.warning('La póliza: ' + policies.number
              + ' no permite dependientes con edad mayor a: ' + maxAgeLimit);
            isAgeAllowed = false;
          }
        }
      });
    });
    return isAgeAllowed;
  }

  onLoadDocuments(event) {
    this.uploadedFilesAntique = event;
  }

  // Convertimos la imágen o PDF a base64
  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    const documents = new Documents();
    this.uploadedFilesAntique = [];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    } else {
      this.getBase64(this.fileUpload).then(
        res => {
          documents.base64 = res.split(',')[1];
          documents.name = this.fileUpload.name;
          this.uploadedFilesAntique.push(documents);
        }
      );
    }
  }

}
