import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../api.service';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {BsModalService} from 'ngx-bootstrap/modal';
import {SharedService} from '../shared-service.service';
import {DatePipe} from '@angular/common';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {Documents} from '../upload-certificate/documents';
import {Observable} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {TypeaheadContainerComponent, TypeaheadDirective, TypeaheadMatch} from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-request-add-ammia',
  templateUrl: './request-add-ammia.component.html',
  styleUrls: ['./request-add-ammia.component.css']
})
export class RequestAddAmmiaComponent implements OnInit, OnDestroy {

  dataRequest: any = [];
  clientList: any = [];
  policyList: any = [];
  dependentList: any = [];
  responseService: number;
  gendersCatalog: any = [];
  policiesSelectedItems: any = [];
  lclInsuredSelected: any = {};
  // variables calendario
  bsBirthdate: Partial<BsDatepickerConfig>;
  bsEntryDate: Partial<BsDatepickerConfig>;

  // Variables para Solicitudes
  uploadedFilesAntique: Array<Documents> = []; // Documentos del cliente
  public fileUpload: File;
  error = {active: false, msg: ''};
  fileTypes: string[] = ['application/pdf'];

  dependentsList: any = [];
  relationshipList: any = []; // Almaccena la lista de parentescos retornada por el servicio
  dataToSend: any = {}; // Almacena los datos de envío
  selected: boolean;
  dataToSendDep: any = {}; // Almacena los datos del dependiente dentro del apartado de Asegurados
  attachDependents = false;

  // Variables para typeahead (búsqueda de asegurados) ->
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;

  // tslint:disable-next-line:variable-name
  @ViewChild('typeaheadDirective') _typeahead: TypeaheadDirective;
  public get container(): TypeaheadContainerComponent {
    return this._typeahead._container;
  }
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService,
              private datePipe: DatePipe) {
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getInsuredAsObservable(token))
    );

    this.shared.fUpdateRequestsAddObservable().subscribe(
      fRequestsAdd => {
        if (fRequestsAdd) {
          console.log('entra a observador');
          this.dataToSend = [];
          this.responseService = 0;
          this.dataRequest.typeAdd = 0;
          this.dataRequest.clientId = 0;
        }
      }
    );
  }

  ngOnDestroy(): void {
    console.log('salir de nueva alta');
  }

  ngOnInit(): void {
    // Iniciamos configuración para DatePicker de Fecha de Nacimiento ->
    this.bsBirthdate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      maxDate: new Date()
    });
    // Iniciamos configuración para DatePicker de Fecha de Alta ->
    const currentDateMinus30Days = new Date();
    currentDateMinus30Days.setDate(currentDateMinus30Days.getDate() - 30);
    this.bsEntryDate = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      adaptivePosition: true,
      showWeekNumbers: false,
      dateInputFormat: 'DD-MM-YYYY',
      minDate: currentDateMinus30Days
    });
    this.dataRequest.typeAdd = 0;
    this.dataRequest.clientId = 0;

  }
  getInsuredAsObservable(token: string): Observable<any> {
    const dataRequest = {
      clientId: this.dataRequest.clientId,
      search: token
    };
    return this.api.getFinderInsuredAmmia(dataRequest).pipe();
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.lclInsuredSelected = e.item;
    console.log(this.lclInsuredSelected);
    this.invokeServiceGetPolicyByUser(this.lclInsuredSelected.userId, this.dataRequest.clientId);
  }
  invokeServiceGetPolicyByUser(idUser: number, idClient: number) {
    const dataRequest = {
      userId: idUser,
      clientId: idClient
    };
    this.api.getPolicyClientsRequestByUser(dataRequest)
      .then(
        (response: any) => {
          if (response != null) {
            this.policyList = response;
          }
        }, error => {
          console.info(error);
        });

  }

  invokeFunctionGetClientByRequest() {
    this.api.getClientsByRequest()
      .then(
        (response: any) => {
          if (response != null) {
            this.clientList = response;
            this.dataRequest.clientId = response[0].clientId;
            console.log(this.dataRequest.clientId);
            if (this.dataRequest.typeAdd === 1) {
              this.invokeFunctionPoliciesByClient(this.dataRequest.clientId);
            }
          }
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionPoliciesByClient(idClient: number) {
    const dataRequest = {
      clientId: idClient
    };
    this.api.getPolicyClientsByRequest(dataRequest)
      .then(
        (response: any) => {
          if (response != null) {
            this.policyList = response;
            console.log(this.policyList);
          }
        }, error => {
          console.info(error);
        });
  }
  private invokeServiceForGetGendersCatalog() {
    this.api.getGendersCatalog()
      .subscribe(
        response => {
          this.gendersCatalog = response;
          this.dataToSend.gender = this.gendersCatalog[0].genderId;
          this.dataToSendDep.gender = this.gendersCatalog[0].genderId;
        }, error => {
          console.error(error);
        }
      );
  }

  onChangeInputFile(e) {
    this.error = {active: false, msg: ''};
    this.fileUpload = e.target.files[0];
    const documents = new Documents();
    this.uploadedFilesAntique = [];
    if (0 > this.fileTypes.indexOf(this.fileUpload.type)) {
      this.error = {active: true, msg: 'Debe seleccionar un archivo válido'};
    } else {
      this.getBase64(this.fileUpload).then(
        res => {
          documents.base64 = res.split(',')[1];
          documents.name = this.fileUpload.name;
          this.uploadedFilesAntique.push(documents);
        }
      );
    }
  }
  // Convertimos la imágen o PDF a base64
  getBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.toString());
      reader.onerror = error => reject(error);
    });
  }


  public activateDependentRegistrationInInsured() {
    this.attachDependents = true;
    this.invokeServiceForGetRelationshipCatalog();
    this.dataToSendDep.entryDate = this.dataToSend.entryDate;
  }
  private invokeServiceForGetRelationshipCatalog() {
    this.api.getRelationshipCat()
      .subscribe(
        response => {
          this.relationshipList = response;
        }, error => {
          console.error(error);
        }
      );
  }

  public addNewDependent() {
    let objToSend01 = {};
    console.log(this.dataRequest.typeAdd);
    switch (this.dataRequest.typeAdd) { // this.lclRequestType
      case 1:
        console.log(this.dataToSendDep);
        if (this.dataToSendDep.relationship === undefined
          || this.dataToSendDep.name === undefined
          || this.dataToSendDep.pLastname === undefined
          || this.dataToSendDep.sLastname === undefined
          || this.dataToSendDep.birthdate === undefined
          || this.dataToSendDep.entryDate === undefined
          || this.dataToSendDep.gender === undefined) {
          this.toastr.warning('Por favor llene todos los campos requeridos');
          return;
        }

        if (this.dataToSendDep.relationship === '2' && !this.onCalculateAgeDependent(this.dataToSendDep.birthdate)) {
          return;
        }
        setTimeout(() => {
          objToSend01 = {
            name: this.dataToSendDep.name,
            firstName: this.dataToSendDep.pLastname,
            lastName: this.dataToSendDep.sLastname,
            birthDate: this.datePipe.transform(this.dataToSendDep.birthdate, 'yyyy-MM-dd'),
            kinshipId: parseInt(this.dataToSendDep.relationship, 10),
            // kinship: relationShip0.name,
            userId: 0,
            startDate: this.datePipe.transform(this.dataToSendDep.entryDate, 'yyyy-MM-dd'),
            gender: this.dataToSendDep.gender,
          };

          this.dependentsList.push(objToSend01);

          this.dataToSendDep.name = undefined;
          this.dataToSendDep.pLastname = undefined;
          this.dataToSendDep.sLastname = undefined;
          this.dataToSendDep.relationship = undefined;
          this.dataToSendDep.birthDate = undefined;
        }, 10);
        break;
      case 2:
        if (this.dataToSend.relationship === undefined
          || this.dataToSend.name === undefined
          || this.dataToSend.pLastname === undefined
          || this.dataToSend.sLastname === undefined
          || this.dataToSend.birthdate === undefined
          || this.dataToSend.entryDate === undefined
          || this.dataToSend.gender === undefined) {
          this.toastr.warning('Por favor llene todos los campos requeridos');
          return;
        }
        if (this.dataToSend.relationship === '2' && !this.onCalculateAgeDependent(this.dataToSend.birthdate)) {
          return;
        }
        setTimeout(() => {
          objToSend01 = {
            name: this.dataToSend.name,
            firstName: this.dataToSend.pLastname,
            lastName: this.dataToSend.sLastname,
            birthDate: this.datePipe.transform(this.dataToSend.birthdate, 'yyyy-MM-dd'),
            kinshipId: parseInt(this.dataToSend.relationship,  10),
            userId: this.lclInsuredSelected.userId,
            startDate: this.datePipe.transform(this.dataToSend.entryDate, 'yyyy-MM-dd'),
            gender: this.dataToSend.gender,
            policyList: undefined
          };

          this.dependentsList.push(objToSend01);

          this.dataToSend.name = undefined;
          this.dataToSend.pLastname = undefined;
          this.dataToSend.sLastname = undefined;
          this.dataToSend.relationship = undefined;
          this.dataToSendDep.birthDate = undefined;
          this.uploadedFilesAntique = [];
        }, 10);
        break;
    }
  }

  onCalculateAgeDependent(birthdate: any): boolean {
    let timeDiff;
    timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());

    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    let isAgeAllowed = true;

    this.policiesSelectedItems.map(x => {
      this.policyList.map(policies => {
        if (policies.categoryId === x.categoryId) {
          const maxAgeLimit = policies.ageMax;
          if (maxAgeLimit !== 0 && Number(age) > maxAgeLimit ) {
            this.toastr.warning('La póliza: ' + policies.number
              + ' no permite dependientes con edad mayor a: ' + maxAgeLimit);
            isAgeAllowed = false;
          }
        }
      });
    });
    return isAgeAllowed;
  }
  invokeFunctionChangeOperation(id: number) {
    // this.shared.fUpdateRequestsAdd.next(true);
    this.responseService = 0;
    this.dataRequest.typeAdd = id;
    this.dataToSend = [];
    this.fileUpload = undefined;
    this.invokeFunctionGetClientByRequest();
    this.invokeServiceForGetGendersCatalog();
    if (id === 2) this.invokeServiceForGetRelationshipCatalog();
  }

  invokeFunctionSaveRequestInsuredAdd() {
    console.log(this.dataToSend);
    if (this.validateDuplicatePolice(this.policyList)) {
      // this.statsend = false;
      return;
    }
    if (!this.validateSelectedPolice(this.policyList)) {
      this.toastr.warning('Es necesario seleccionar al menos una categoría');
      // this.statsend = false;
      return;
    }

    console.log(this.dataToSendDep);

    if (this.dataToSendDep.relationship === '2' && !this.onCalculateAgeDependent(this.dataToSendDep.birthdate)) {
      // this.statsend = false;
      return;
    }

    if (this.dataToSendDep.name !== undefined
      && this.dataToSendDep.pLastname !== undefined
      && this.dataToSendDep.sLastname !== undefined
      && this.dataToSendDep.birthdate !== undefined
      && this.dataToSendDep.relationship !== undefined
      && this.dataToSendDep.gender !== undefined) {
      // setTimeout(() => {
      const lastDependent = {
        name: this.dataToSendDep.name,
        firstName: this.dataToSendDep.pLastname,
        lastName: this.dataToSendDep.sLastname,
        birthDate: this.datePipe.transform(this.dataToSendDep.birthdate, 'yyyy-MM-dd'),
        kinshipId: parseInt(this.dataToSendDep.relationship,  10),
        userId: 0,
        startDate: this.datePipe.transform(this.dataToSendDep.entryDate, 'yyyy-MM-dd'),
        gender: this.dataToSendDep.gender,
      };
      this.dependentsList.push(lastDependent);

    }

    const objToSend00 = {
      name: this.dataToSend.name,
      firstName: this.dataToSend.pLastname,
      lastName: this.dataToSend.sLastname,
      email: this.dataToSend.email.toLowerCase().trim(),
      number: this.dataToSend.employeeNumber,
      birthDate: this.datePipe.transform(this.dataToSend.birthdate, 'yyyy-MM-dd'),
      startDate: this.datePipe.transform(this.dataToSend.entryDate, 'yyyy-MM-dd'),
      salary: this.dataToSend.salary,
      gender: this.dataToSend.gender,
      policySubranchCategories: this.policyList,
      policyList: [],
      dependetList: this.dependentsList,
      baseAntique: this.uploadedFilesAntique.length === 0 ? '' : this.uploadedFilesAntique[0].base64,
      nameFileAntique: this.uploadedFilesAntique.length === 0 ? '' : this.uploadedFilesAntique[0].name,
      clientId: this.dataRequest.clientId
    };
    console.log(objToSend00);
    this.responseService = 1;
    this.api.postRequestInsuredAddAmmia(objToSend00)
      .subscribe(
        response => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.shared.fUpdateRequests.next(true);
          this.responseService = 2;
        }, error => {
          if (error.status === 500) {
            this.responseService = 3;
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
              this.dependentsList = [];
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
  }

  validateDuplicatePolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let foundedPolicyRepetead = false;
    for (const policySubranchCategory of policySubranchCategories) {
      let policy;
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          if (policy === policySubranchCategory.idPolicy) {
            this.toastr.warning('Solo puede asignarse una categoría por póliza, favor de modificar las pólizas seleccionadas');
            foundedPolicyRepetead = true;
            return;
          } else {
            policy = policySubranchCategory.idPolicy;
          }

          policy = policySubranchCategory.idPolicy;
        }
      }
    }
    return foundedPolicyRepetead;
  }

  validateSelectedPolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let isSelected = false;
    for (const policySubranchCategory of policySubranchCategories) {
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          isSelected = true;
        }
      }
    }
    return isSelected;
  }

  invokeFunctionSaveRequestInsuredAddDependent() {
    const listToSend01 = [];
    if ((this.dataToSend.relationship === undefined
      || this.dataToSend.name === undefined
      || this.dataToSend.pLastname === undefined
      || this.dataToSend.sLastname === undefined
      || this.dataToSend.birthdate === undefined
      || this.dataToSend.entryDate === undefined
      || this.dataToSend.gender === undefined) && this.dependentsList.length === 0) {
      this.toastr.warning('Por favor llene todos los campos requeridos');
      return;
    }

    if (this.dataToSend.relationship === '2' && !this.onCalculateAgeDependent(this.dataToSend.birthdate)) {
      return;
    }
    if (!this.validateSelectedPolice(this.policyList)) {
      this.toastr.warning('Es necesario seleccionar al menos una categoría');
      return;
    }
    for (const dependent of this.dependentsList) {
      delete dependent.kinship;
      dependent.policyList = [];
      dependent.policySubranchCategories = this.policyList;
      dependent.clientId = this.dataRequest.clientId;
      listToSend01.push(dependent);
    }
    listToSend01.push({
      name: this.dataToSend.name,
      firstName: this.dataToSend.pLastname,
      lastName: this.dataToSend.sLastname,
      birthDate: this.datePipe.transform(this.dataToSend.birthdate, 'yyyy-MM-dd'),
      kinshipId: Number(this.dataToSend.relationship),
      userId: this.lclInsuredSelected.userId,
      startDate: this.datePipe.transform(this.dataToSend.entryDate, 'yyyy-MM-dd'),
      gender: this.dataToSend.gender,
      policyList: [],
      policySubranchCategories: this.policyList,
      clientId: this.dataRequest.clientId
    });
    console.log(listToSend01);
    this.responseService = 1;
    this.api.postRequestDependentAddAmmia(listToSend01)
      .subscribe(
        response => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.shared.fUpdateRequests.next(true);
          this.responseService = 2;
        }, error => {
          console.error(error);
          this.responseService = 3;
          if (error.status === 500) {
            if (error.error.message !== undefined) {
              this.toastr.warning(error.error.message, 'Notificación');
              this.dependentsList = [];
            } else {
              this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
            }
          } else {
            this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
          }
        }
      );
  }
  invokeFunctionClear() {
    this.dataRequest.typeAdd = 0;
    this.policyList = [];
    this.responseService = 0;
    this.shared.fUpdateRequestsAdd.next(true);
  }
}
