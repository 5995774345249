<!-- Modal de MODIFICAR ALTA MULTIPLE MODIFIRAC DATOS -->
<div class="modal fade" id="altaMultipleValidacionModificarDatos"  tabindex="-1" aria-labelledby="verEventoLabel" aria-hidden="true"
     data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered justify-content-center modal-dialog-scrollable modal-xl xxl">
    <div class="modal-content evento">
      <div class="modal-header evento">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="d-column-start gap-8">
          <h3 class="d-flex gap-8 modal-title " id="verEventoLabel">
            MOVIMIENTOS
          </h3>
        </div>

      </div>
      <div class="modal-body">
        <div>
          <div
            style="background-color: #ffffff; border-radius: 5px; padding-top: 30px;box-shadow: 0 5px 25px 0 rgba(41, 63, 121, 0.05);">
            <div class="">
              <div style="text-align: right;" class="nav nav-pills nueva-solicitud mb-3 w-100 d-around gap-16">
                <div class="btn-group pull-left ">
                  <button class="btn btn-xs btn-custom pull-right secundario" href="javascript:;" (click)="addMovment()">
                    <i class="bi bi-plus-circle"></i>&nbsp;&nbsp;Agregar movimiento
                  </button>
                </div>
                <div class="btn-group pull-left ">
                  <button class="btn btn-xs btn-custom pull-right secundario" href="javascript:;" (click)="invokeEditRequestTempDetail()">
                    <i class="bi bi-floppy"></i>&nbsp;&nbsp;Guardar movimientos
                  </button>
                </div>
                <div class="btn-group pull-left">
                  <button class="btn btn-xs btn-custom pull-right secundario" href="javascript:;"
                          (click)="invokeServiceProcessMassiveRequest()">
                    <i class="bi bi-cloud-arrow-up-fill"></i>&nbsp;&nbsp;Procesar Solicitudes
                  </button>
                </div>
                <div class="pull-left subtitle-section-menu">
                  Última vez
                  guardado {{requestTemp === undefined ? '' : requestTemp.lastUpdate === undefined ? '' : requestTemp.lastUpdate | date: 'dd/MM/yyyy HH:mm:ss'}}
                </div>
              </div>
              <div class="">
                <div class="table-responsive">
                  <table class="table" [formGroup]="requestsForm">
                    <thead>
                    <tr>
                      <th class="title-th">OPERACIÓN</th>
                      <th class="title-th">PARENTESCO</th>
                      <th class="title-th">NOMBRE</th>
                      <th class="title-th">APELLIDO PATERNO</th>
                      <th class="title-th">APELLIDO MATERNO</th>
                      <th class="title-th">EMAIL</th>
                      <th class="title-th">GENERO</th>
                      <th class="title-th">FECHA DE NACIMIENTO</th>
                      <th class="title-th">NUMERO EMPLEADO</th>
                      <th class="title-th">FECHA ALTA/BAJA</th>
                      <th class="title-th">SUELDO</th>
                      <ng-container
                        *ngFor="let subranch of policyList(requestDetail.controls[0]).controls">
                        <th class="title-th" style="border: #4E4E4E; border-radius: 15px; background-color: rgba(92,94,94,0.18); text-align: center" [colSpan]="categories(subranch).length">
                          {{subranch.value.subranch + ' ' +subranch.value.numberPolicy}}
                        </th>
                      </ng-container>
                      <th class="title-th">ERROR</th>
                      <th class="title-th">OPERACIÓN</th>
                    </tr>
                    </thead>
                    <tbody formArrayName="requestDetail">
                    <tr *ngFor="let detail of requestDetail.controls; let i = index" [formGroupName]="i"
                        [ngStyle]="{'background-color': (getIsValid(detail)) ? 'rgba(255,0,0,0.2)':''}">
                      <td>
                    <span>
                      <select class="form-control selectors" formControlName="operacion"
                              id="{{'select' + detail.get('requestTempDetailId').value}}">
                        <option *ngFor="let operation of getOperationsType()" [value]="operation">{{operation}}</option>
                      </select>
                   </span>
                      </td>
                      <td>
                    <span>
                      <select class="form-control selectors" formControlName="parentesco" required
                              id="{{'kinshipSelect' + detail.get('requestTempDetailId').value}}">
                        <option *ngFor="let kinship of getKinshipType()" [value]="kinship">{{kinship}}</option>
                      </select>
                   </span>
                      </td>
                      <td><input style="width: 120px; text-transform: uppercase" class="form-control inputs-request"
                                 formControlName="nombre" contenteditable="true"/></td>
                      <td><input style="width: 120px; text-transform: uppercase" class="form-control inputs-request"
                                 formControlName="apellidoPaterno" contenteditable="true"/></td>
                      <td><input style="width: 120px; text-transform: uppercase" class="form-control inputs-request"
                                 formControlName="apellidoMaterno" contenteditable="true"/></td>
                      <td><input style="width: 120px" class="form-control inputs-request" formControlName="email"
                                 contenteditable="true"/></td>
                      <td>
                     <span>
                      <select class="form-control selectors" formControlName="genero">
                        <option *ngFor="let gender of getGenderType()" [value]="gender">{{gender}}</option>
                      </select>
                   </span>
                      </td>
                      <td><input type="text"
                                 placeholder="00-00-0000"
                                 class="form-control date-picker-request"
                                 bsDatepicker
                                 [bsConfig]="bsBirthdate"
                                 name="birthdate"
                                 formControlName="fechaNacimiento"
                                 required></td>
                      <td><input class="form-control inputs-request" formControlName="numeroEmpleado" contenteditable="true"/>
                      </td>
                      <td><input type="text"
                                 placeholder="00-00-0000"
                                 class="form-control date-picker-request"
                                 bsDatepicker
                                 [bsConfig]="bsDateMovement"
                                 name="dateMovement"
                                 formControlName="fechaAltaBaja"
                                 required></td>
                      <td><input class="form-control inputs-request" formControlName="sueldo" contenteditable="true"/></td>
                      <ng-container formArrayName="policySubranchCategories">
                        <ng-container *ngFor="let subranch of policyList(detail).controls;  let i = index" [formGroupName]="i">
                          <ng-container formArrayName="policyCategoriesList">
                            <td *ngFor="let category of categories(subranch).controls; let cat = index"
                                [formGroupName]="cat">
                      <span>
                      <label class="categories-options"> <input type="checkbox" formControlName="selected"/> {{category.value.category}}</label>
                      </span>
                            </td>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                      <td><input style="width: min-content" disabled class="form-control inputs-request"
                                 formControlName="error"/></td>
                      <td style="text-align: center">
                        <div id="icon" style="display: inline-block;width: 30%;">
                          <i class="glyphicon glyphicon-remove-circle" style="color: #326173;" (click)="removeMovement(i)"></i>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <!--</div>-->
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-footer">
        <button class="btn secundario mb-4" id="btnContinuar" type="button" data-bs-dismiss="modal" aria-label="Close">
          <img src="../../assets/img/ammia/iconos/back-azul-ammia.svg" alt="check" class="icono-sm">

          Volver
        </button>
      </div>

    </div>
  </div>
</div>
