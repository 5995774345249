<div >
  <div *ngIf="responseService == 0">
    <form action="">

      <!--- cliente de solicitud general --->
      <div class="form-row">
        <div class="form-group">
          <label >Razón Social</label>
          <select class="form-select ammiaAdmin-style  mb-3" aria-label=" example" [(ngModel)]="dataRequest.clientId"
                  name="relationshipDep" required (change)="invokeFunctionGetPoliciesByClient()" >
            <option *ngFor="let item of clientList" [value]="item.clientId"
            >{{item.name}}</option>
          </select>

        </div>
      </div>

      <!-- SEPARADOR -->
      <div class="separador-tarjetas">
        <h4>Datos del vehículo</h4>
        <hr>
      </div>

      <div class="form-row mt-4">

        <div class="form-group">
          <label class="control-label">Buscar vehiculo</label>
          <ng-template #customItemTemplate let-model="item" let-index="index">
            <p >{{ model.name}}</p>
          </ng-template>
          <ng-template #customListTemplate let-matches="matches" let-itemTemplate="itemTemplate" let-query="query">
            <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
            <span *ngIf="match.isHeader()">
                {{match}}
            </span>
              <div class="global-search-option"
                   (click)="container.selectMatch(match, $event)">
                <ng-template [ngIf]="!match.isHeader()">
                  <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{item: match.item, index: i, match: match, query: query}"></ng-template>
                </ng-template>
              </div>
            </ng-template>
          </ng-template>


          <input [(ngModel)]="searchItemSelected"
                 [typeaheadAsync]="true"
                 [typeahead]="dataSource"
                 (typeaheadLoading)="changeTypeaheadLoading($event)"
                 (typeaheadOnSelect)="typeaheadOnSelect($event)"
                 [typeaheadOptionsLimit]="7"
                 typeaheadWaitMs="1000"
                 adaptivePosition = true
                 typeaheadOptionField="version"
                 placeholder="Buscar…"
                 class="form-control"
                 name="insured"
                 autocomplete="off">
          <div *ngIf="typeaheadLoading">Loading</div>
          <img src="../../assets/img/ammia/iconos/search.svg" alt="Ícono de Agregar tarea" class="search-icon">
        </div>

      </div>


      <!-- SEPARADOR -->
      <div class="separador-tarjetas">
        <h4>Póliza</h4>
        <hr>
      </div>

      <div class="w-100 d-center">
        <ng-container *ngFor="let subranch of policiesList;  let i = index">
          <div class="poliza-vehiculo-card">
            <h5>{{subranch.subranch }}</h5>
            <h6>{{ subranch.numberPolicy}}</h6>
            <ng-container *ngFor="let category of subranch.policyCategoriesList; let cat = index">
              <input type="checkbox" [(ngModel)]="category.selected" [ngModelOptions]="{standalone: true}"/>
              <label
                style="font-size: xx-small; vertical-align: middle; color: #326173"> {{category.category}}</label>
              <br>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div class="form-group form-group-sm">
        <label class="control-label">Fecha de baja</label>
        <div class="input-group">
          <div class="input-group-addon"><i class="bi bi-calendar"></i></div>
          <input type="text"
                 placeholder="00-00-0000"
                 class="form-control"
                 bsDatepicker
                 [bsConfig]="bsLeavingDate"
                 [(ngModel)]="dataRequest.leavingDate"
                 name="leavingDate"
                 required>
        </div>
      </div>

      <div class=" offcanvas-footer d-center mt-3">
        <button class="btn primario" id="btnGenerarSolicitudCancel" type="button"
                (click)="invokeFunctionSaveRequestCarCancelled()">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.5237 6.98805L10.2319 14.3279L7.52681 11.5317L5.99787 13.0463L9.17336 16.3084C9.29097 16.4249 9.52619 16.5415 9.76142 16.658C9.99664 16.7745 10.2319 16.7745 10.3495 16.7745C10.4671 16.7745 10.8199 16.7745 10.9375 16.658C11.1727 16.5415 11.2904 16.4249 11.5256 16.3084L19.1703 8.50262L17.5237 6.98805Z" />
            <path d="M20.5819 3.49515C18.3473 1.28155 15.2894 0 11.9963 0C8.82081 0 5.76293 1.28155 3.52833 3.49515C1.29372 5.82524 0 8.73786 0 12C0 15.1456 1.29372 18.1748 3.52833 20.5049C5.76293 22.7184 8.82081 24 11.9963 24C15.1718 24 18.2297 22.7184 20.4643 20.5049C22.6989 18.2913 23.9926 15.2621 23.9926 12C24.1102 8.73786 22.8165 5.82524 20.5819 3.49515ZM12.1139 21.6699C6.70382 21.6699 2.35222 17.2427 2.35222 11.8835C2.35222 6.52427 6.70382 2.09709 12.1139 2.09709C17.524 2.09709 21.8756 6.52427 21.8756 11.8835C21.8756 17.3592 17.524 21.6699 12.1139 21.6699Z" />
          </svg>

          Generar solicitud
        </button>
      </div>

    </form>
  </div>
  <div class="w-100 d-column-center p-3 p-md-5" *ngIf="responseService == 2">
    <a href="javascript:;"   aria-controls="offcanvasRight">
      <dotlottie-player src="https://lottie.host/bb64e4a0-a834-4f97-8152-fba5e5234f5a/3vObIm0Ks5.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
    </a>

    <p class="">Se ha generado el reporte de tu solicitud. Un ejecutivo Surexs te contactará lo antes posible y te brindará atención.</p>


    <div class="d-center w-100 mt-5">
      <button class="btn primario" id="btnGenerarSolicitudVehiculog" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
        <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
        Entendido
      </button>
    </div>
  </div>
  <div class=""  *ngIf="responseService == 1">
    <div class="w-100 d-column-center">
      <a href="javascript:;"  aria-controls="offcanvasRight" >
        <dotlottie-player src="https://lottie.host/06fc7b75-f5d2-4524-86f8-fbbe96bf456a/VGiGPUrFSr.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
      </a>
      <div>
        <p class="solicitud-parrafo">Estamos validando tu solicitud. No cierres ni actualices esta venta. </p>
      </div>
    </div>
  </div>
  <div  *ngIf="responseService == 3">
    <a href="javascript:;"   aria-controls="offcanvasRight" >
      <dotlottie-player src="https://lottie.host/4c9dabcd-e6c6-41c2-84c9-7cf1b79890af/yn2rFJ1ICK.json" background="transparent" speed="1" style="width: 200px; height: 200px;" loop autoplay></dotlottie-player>
    </a>
    <div>
      <p class="solicitud-parrafo">Se ha producido un error al dar de alta la solicitud, por favor contacta al administrador.
      </p>
    </div>
    <div class="d-center w-100 mt-5">
      <button class="btn primario" id="btnGenerarSolicitudVehiculogg" type="button" data-bs-dismiss="offcanvas" aria-label="Close" (click)="invokeFunctionClear()">
        <img src="../../assets/img/ammia/iconos/check-azuld2.svg" alt="" class="icono-sm">
        cerrar
      </button>
    </div>
  </div>
</div>
