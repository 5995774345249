import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../api.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {BsModalService} from "ngx-bootstrap/modal";
import {SharedService} from "../shared-service.service";

@Component({
  selector: 'app-request-general-ammia',
  templateUrl: './request-general-ammia.component.html',
  styleUrls: ['./request-general-ammia.component.css']
})
export class RequestGeneralAmmiaComponent implements OnInit {
  myFormRequestGeneral: FormGroup;
  dataRequest: any = [];
  clientList: any = [];
  policyList: any = [];
  responseService: number;
  constructor(private api: ApiService,
              private fb: FormBuilder,
              private router: Router,
              private toastr: ToastrService,
              private modalService: BsModalService,
              public shared: SharedService) {
    this.myFormRequestGeneral = this.fb.group({
      client: ['', [Validators.required]],
      subject: ['',  [Validators.required, Validators.maxLength(50)]],
      description: [''],
      polizaMaestraSolicitud: [''],
      directoresSolicitud1: ['']
    });
    this.shared.fUpdateRequestsGeneralObservable().subscribe(
      fRequestsGeneral => {
        if (fRequestsGeneral) {
          console.log('entra');
          if (fRequestsGeneral) {
            this.responseService = 0;
            this.invokeFunctionGetClientByRequest();
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this.responseService = 0;
  }

  invokeFunctionGetClientByRequest() {
    this.api.getClientsByRequest()
      .then(
        (response: any) => {
          if (response != null) {
            this.clientList = response;
            this.dataRequest.clientId = response[0].clientId;
            this.invokeFunctionGetPoliciesByClient();
          }
        }, error => {
          console.info(error);
        });
  }

  invokeFunctionPoliciesByClient(idClient: number) {
    const dataRequest = {
      clientId: idClient
    };
    this.api.getPolicyClientsByRequest(dataRequest)
      .then(
        (response: any) => {
          if (response != null) {
            this.policyList = response;
            console.log(this.policyList);
          }
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionGetPoliciesByClient() {
    this.invokeFunctionPoliciesByClient(this.dataRequest.clientId);
  }
  invokeFunctionSaveRequest() {
    console.log('entra');
    if (this.dataRequest.clientId === 0) {
      this.toastr.info('Debe de selccionar el subgrupo para poder visualizar las pólizas.', 'Notificación');
      return;
    }
    if (this.dataRequest.subject === '' || this.dataRequest.description === '') {
      this.toastr.info('Por favor debe de ingresar toda la información necesaria para dar de alta la solicitud', 'Notificación');
      return;
    }
    if (!this.validateSelectedPolice(this.policyList)) {
      this.toastr.warning('Es necesario seleccionar al menos una categoría');
      return;
    }
    this.responseService = 1;
    const dataRequestGeneral = {
      requestId: this.dataRequest.clientId,
      subject: this.dataRequest.subject,
      description: this.dataRequest.description,
      isincumbent: true,
      employedNum: '',
      policySubranchCategories: this.policyList
    };
    console.log(dataRequestGeneral);
    this.api.saveRequestBenefits(dataRequestGeneral)
      .then(
        (response) => {
          this.toastr.success('Su solicitud se ha realizado de forma exitosa', 'Notificación');
          this.shared.fUpdateRequests.next(true);
          this.responseService = 2;
        }, error => {
          this.responseService = 3;
          console.error(error.status);
          console.error(error.statusText);
          console.error(error.message);
          this.toastr.error('Ocurrió un problema al procesar su petición', 'Notificación');
        }
      );
  }

  validateSelectedPolice(policySubranchCategories: any): boolean {
    const policySelected = [];
    let isSelected = false;
    for (const policySubranchCategory of policySubranchCategories) {
      for (const policyCategory of policySubranchCategory.policyCategoriesList) {
        if (policyCategory.selected) {
          isSelected = true;
        }
      }
    }
    return isSelected;
  }

  invokeFunctionClear() {
    this.shared.fUpdateRequestsGeneral.next(false);
    this.shared.fUpdateRequests.next(true);
  }

}
