import {Component, HostListener, OnInit} from '@angular/core';
import {ApiService} from '../api.service';
import {SharedService} from '../shared-service.service';
import {Router} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import {Observable} from 'rxjs';
import {TypeaheadMatch} from 'ngx-bootstrap/typeahead';
import {mergeMap} from 'rxjs/operators';

@Component({
  selector: 'app-navbar-ammia',
  templateUrl: './navbar-ammia.component.html',
  styleUrls: ['./navbar-ammia.component.css']
})
export class NavbarAmmiaComponent implements OnInit {
// -Variables para typeahead ->
  searchItemSelected: string;
  typeaheadLoading: boolean;
  dataSource: Observable<any>;
  itemSelected: any = {};
  showNotification: boolean;
  showChat: boolean;
  // -Variables para typeahead <-
  user: any = {};
  insured: any = {};

  widthSize: number = window.innerWidth;

  // -Variables para eventos ->
  totalEventsRecords = 0;
  eventsList: any = [];
  eventPage = 1;
  // -Variables para eventos <-

  // - variables para perfil
  userUpdate;
  oldpassword: string;
  newpassword: string;
  newpasswordC: string;
  // variables para perfil <-
  loadModal: number;
  selectRequest: number;
  selectRequestCar: number;
  currentPasswordB: string;
  newPasswordB: string;
  confirmPasswordB: string;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.widthSize = window.innerWidth;
  }
  constructor(private api: ApiService,
              public shared: SharedService,
              private router: Router,
              private modalService: BsModalService,
              private toastr: ToastrService) {

    /* se ponen por default para probar busqueda */
    this.shared.gCurrentCustomerId.next(1193);
    this.shared.gCurrentBranchId.next(1);
    this.shared.gCurrentRoleId = Number(localStorage.getItem('roleId'));

    this.getProfile();

    this.shared.fUpdateProfileObservable().subscribe(
      fProfile => {
        if (fProfile) {
          this.getProfile();
          this.shared.fUpdateProfile.next(false);
        }
      }
    );


    /**
     * Observable para monitorear los cambios en la barra de Búsqueda
     */
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.searchItemSelected);
    }).pipe(
      mergeMap((token: string) => this.getStatesAsObservable(token))
    );
    this.itemSelected = {
      id: 0,
      type: 0,
      title: '',
      description: ''
    };
  }

  ngOnInit(): void {
    this.selectRequest = 1;
    this.loadModal = 0;
    this.invokeServiceForGetEventLogs();
    this.newpassword = '';
    this.oldpassword = '';
    this.showNotification = false;
    this.showChat = false;
    this.currentPasswordB = 'password';
    this.newPasswordB = 'password';
    this.confirmPasswordB = 'password';
  }

  /* Funciones para perfil */
  getProfile() {
    this.api.getUserProfile()
      .then(
        (data) => {
          this.user = data;
          console.log(this.user);
          this.shared.gProfileImg = this.user.imageProfile;
          this.shared.gProfileName = this.user.name;
          this.shared.gProfilePosition = this.user.position;
        }, error => {
          console.log(error);
        }
      );
  }

  closeSession() {
    const dataRemember = JSON.parse(localStorage.getItem('remember'));
    console.log(dataRemember);
    localStorage.clear();
    if (dataRemember !== null) {
      localStorage.setItem('remember', JSON.stringify(dataRemember));
    }
    this.shared.fStreamRoomMessages.next(false);
    window.open('/', '_self');
  }

  /*----------------------------------------------------------------------------------------------------------------------------------------
  -----------------------------------B U S Q U E D A    G E N E R A L----------------------------------------------------------------------
  ---------------------------------------------------------------------------------------------------------------------------------------*/
  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }
  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.itemSelected = e.item;
    console.log(this.itemSelected);
    this.loadModal = this.itemSelected.type;
    switch (this.itemSelected.type) {
      case 1:
        this.shared.gPolicyId.next(this.itemSelected.id);
        break;
      case 2:
        this.shared.sIncidentId.next(this.itemSelected.id);
        break;
      case 3:
        localStorage.setItem('requestAux', this.itemSelected.aux);
        this.shared.gRequestId.next(this.itemSelected.id);
        break;
      case 4:
        this.insured = {
          userId: this.itemSelected.id,
          name: this.itemSelected.title,
          dependet: null,
          employeeNumber: null,
          created: null
        };
        localStorage.setItem('insuredView', JSON.stringify(this.insured));
        this.shared.pgInsured.next(this.insured.userId);
    }
  }

  getStatesAsObservable(token: string): Observable<any> {
    return this.api.getFinderResult(token).pipe();
  }

  /*-----------------------------------------------------------------------------------------------------------
    -----------------------------------E V E N T O S-----------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------*/

  onScroll() {
    if (this.totalEventsRecords === this.eventsList.length) {
      return;
    }
    this.eventPage++;
    this.invokeServiceForGetEventLogs();
  }

  /**
   * Método para obtener una lista de Eventos
   * la lista se obtiene a través del servicio REST @getEventLog
   * se indican los parametros de paginado Número de Pagina, Tamaño del pagiinado
   *
   * 1.- Invocamos el servicio rest y recuperamos la respuesta en nuestra variable @eventsList
   */
  invokeServiceForGetEventLogs() {
    this.api.getEventLog(this.eventPage, 5)
      .then(
        (response: any) => {
          if (response != null) {
            this.totalEventsRecords = response.total;
            this.eventsList = this.eventsList.concat(response.events);
            console.log(this.eventsList);
          }
        }, error => {
          console.info(error);
        });
  }
  invokeFunctionOpenNotification() {
    if (this.showNotification) {
      this.showNotification = false;
    } else {
      this.showNotification = true;
    }
  }
  invokeFunctionOpenChat() {
    if (this.showChat) {
      this.showChat = false;
    } else {
      this.showChat = true;
    }
  }

  /*-----------------------------------------------------------------------------------------------------------
      -----------------------------------P E R F I L-----------------------------------------------------------
      -----------------------------------------------------------------------------------------------------------*/
  InvokeFunctionDisplayWindowsAvatar() {
    const ventanaAvatar = document.getElementById('ventanaAvatar');
    const ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');
    ventanaAvatar.classList.toggle('active');
    ventanaCambioContrasena.classList.remove('active');
  }


  invokeFunctionChangePassword() {
    const ventanaAvatar = document.getElementById('ventanaAvatar');
    const ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');
    ventanaAvatar.classList.remove('active');
    ventanaCambioContrasena.classList.add('active');
    this.newpassword = '';
    this.oldpassword = '';
    this.newpasswordC = '';
  }

  invokeFunctionBackWindowAvatar() {
    const ventanaAvatar = document.getElementById('ventanaAvatar');
    const ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');

    ventanaAvatar.classList.add('active');
    ventanaCambioContrasena.classList.remove('active');
  }

  InvokeFunctionFinish() {
    console.log('change password');
    if (this.oldpassword !== '' && this.newpassword !== '') {
      if (this.newpassword !== this.newpasswordC) {
        this.toastr.warning('Notificación', 'Las contraseña nueva no coincide, verificar los datos');
        return;
      }
      this.api.updatePassword(this.oldpassword, this.newpassword)
        .then(
          (user) => {
            localStorage.setItem('token', window.btoa(this.user.email + ':' + this.newpassword));
            this.toastr.success('Su información ha sido actualizada correctamente.', 'Notificación');
            this.shared.fUpdateProfile.next(true);
            const ventanaAvatar = document.getElementById('ventanaAvatar');
            const ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');
            const ventanaCambioContrasenaFinalizar = document.getElementById('ventanaCambioContrasenaFinalizar');

            ventanaCambioContrasena.classList.remove('active');
            ventanaCambioContrasenaFinalizar.classList.add('active');
          }, error => {
            if (error.status === 403) {
              this.toastr.warning('La contraseña actual no corresponde. Por favor, revisa e inténtalo de nuevo.', 'Notificación');
            } else {
              this.toastr.error('Ha sucedido un error con el servidor, contacte al administrador.', 'Notificación');
            }
          }
        );
    } else {
      this.toastr.warning('Por favor ingrese los datos solicitados.', 'Notificación');
    }
  }
  invokeFunctionCloseWindowsAvatar() {
    const ventanaAvatar = document.getElementById('ventanaAvatar');
    const ventanaCambioContrasena = document.getElementById('ventanaCambioContrasena');
    const ventanaCambioContrasenaFinalizar = document.getElementById('ventanaCambioContrasenaFinalizar');

    ventanaAvatar.classList.remove('active');
    ventanaCambioContrasena.classList.remove('active');
    ventanaCambioContrasenaFinalizar.classList.remove('active');
  }

  invokeFunctionLoadDataRequestGeneral() {
    console.log('click en solicitud general');
    this.shared.fUpdateRequestsGeneral.next(false);
    this.shared.fUpdateRequestsGeneral.next(true);
    this.selectRequest = 3;
    console.log(this.selectRequest);
  }
  invokeFunctionSelectCancellation() {
    this.shared.fUpdateRequestsCancellation.next(false);
    this.shared.fUpdateRequestsCancellation.next(true);

  }
  invokeFunctionAdd() {
    // this.shared.fUpdateRequestsAdd.next(true);
  }
  invokeFunctionSelectRequest(id: number) {
    this.selectRequest = id;
    console.log(this.selectRequest);
  }

  invokeFunctionSelectRequestCar(id: number) {
    this.selectRequestCar = id;
    switch (id) {
      case 1:
        this.shared.fUpdateRequestsAddCar.next(true);
        break;
      case 2:
        this.shared.fUpdateRequestsCancellationCar.next(true);
        break;
      case 3:
        this.shared.fUpdateRequestsGeneralCar.next(true);
        break;
    }
  }

  invokeFunctionShowRequest() {
    this.shared.fUpdateBenefits.next(true);
    this.shared.fUpdateDamages.next(true);
    this.shared.fUpdateCars.next(true);
  }
  invokeFunctionLoadRequestGeneralDamage() {
    this.shared.fUpdateDamagesChanges.next(true);
  }

  invokeFunctionSeePassword(id: number) {
    switch (id) {
      case 1:
        if (this.currentPasswordB === 'password') {
          this.currentPasswordB = 'text';
        } else {
          this.currentPasswordB = 'password';
        }
        break;
      case 2:
        if (this.newPasswordB === 'password') {
          this.newPasswordB = 'text';
        } else {
          this.newPasswordB = 'password';
        }
        break;
      case 3:
        if (this.confirmPasswordB === 'password') {
          this.confirmPasswordB = 'text';
        } else {
          this.confirmPasswordB = 'password';
        }
        break;
    }
  }
}
